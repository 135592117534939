<template>
    <div style="font-family: Questrial;" class="flex flex-col w-full gap-1 items-center justify-center mt-6">
        <p class="text-base text-gray-600 font-semibold text-center w-full">Step <span class="text-error">{{ use_increament ? (current + use_increament) : current }}</span> Of {{ String(total) }}</p>
        <progress class="progress progress-info w-56" :value="((use_increament ? (current + use_increament) : current) * 100) / total" max="100"></progress>
    </div>
</template>

<script>
export default {
    name: 'ProgressSteps',
    props: ['current', 'total', 'use_increament'],
}
</script>
