<template>
    <Navbar :use_bg="true" logo_size="small" logo_mobile_size="small" />
    <div class="w-full p-10 pt-8" style="font-family: Questrial;">
        <div class="flex flex-col items-center justify-start w-full bg-base-100 overflow-x-hidden overflow-y-auto" v-if="items && items.length > 0">
            <p class="text-gray-700 text-2xl w-full text-center md:text-left whitespace-nowrap">{{ 'Search results for "' + search_text + ' "' }}</p>
            <div class="grid grid-cols-1 md:grid-cols-2 w-full mt-10 gap-2 md:gap-8">
                <template v-for="(item, index) in items" :key="index">
                    <div class="flex flex-col items-center justify-center w-full">
                        <div class="flex flex-col md:flex-row w-full items-center justify-center gap-8 md:max-h-[300px] overflow-hidden">
                            <div class="relative w-full md:max-w-[50%] max-h-60 h-full group overflow-hidden md:max-h-[300px]">
                                <router-link :to="'/post/' + item.slug">
                                    <img class="object-cover w-full max-h-60 h-full md:max-h-[300px] transition-transform duration-500 ease-out group-hover:scale-110 cursor-pointer" :src="this.$store.state.base_url + item.personal_image" :alt="item.title + ' Image'" />
                                </router-link>
                            </div>
                            <div class="flex flex-col items-center justify-start gap-1 w-full">
                                <p class="text-xl font-bold w-full text-black text-left">{{ item.title }}</p>
                                <router-link :to="'/category/' + item.content_category.slug" class="text-left w-full" v-if="item.content_category">
                                    <p class="text-sm font-bold uppercase text-red-500">{{ item.content_category.name }}</p>
                                </router-link>
                                <router-link :to="'/artcategory/' + item.artist_category.slug" class="w-full" v-if="item.artist_category">
                                    <p class="text-sm font-bold text-left uppercase w-full text-blue-400">{{ item.artist_category.name }}</p>
                                </router-link>
                                <p v-else class="text-sm font-bold text-left w-full uppercase text-blue-400">{{ item.custom_artist_category }}</p>
                                <p class="text-base text-gray-700 w-full font-semibold text-left">{{ item.short_description }}</p>
                            </div>
                        </div>
                        <div class="divider md:hidden"></div>
                    </div>
                </template>
            </div>
        </div>
        <NoDataFound v-if="!items || items.length == 0" />
    </div>
    <Footer logo_size="normal" logo_mobile_size="small" class="p-10"></Footer>
</template>

<script>
import Navbar from '@/components/navbar.vue';
import Footer from '@/components/footer.vue';
import NoDataFound from '@/components/no-data-found.vue';
import axios from 'axios';

export default {
    name: 'SearchPage',
    components: {
        Navbar,
        Footer,
        NoDataFound,
    },
    data() {
        return {
            items: [],
            search_text: '',
        }
    },
    mounted() {
        this.awake();
    },
    methods: {
        // awake and refresh data
        async awake() {
            document.title = 'History Of Colors - Search';
            this.scrollToTop();
            this.$store.state.is_loading = true;
            if(this.$route.query.stext)
            {
                await axios.post('/api/v1/data/get/', {type: 'search_data', stext: this.$route.query.stext} , this.$store.state.axios_config)
                    .then(response => {
                        this.items = response.data.posts;
                        this.search_text = this.$route.query.stext;
                        this.$store.state.base_data = response.data.base;
                    })
                    .catch(error => {
                        if(error.response.status == 403)
                            this.$store.dispatch('addToast', {msg: 'You do not have access to this information', type: 'error'});
                        else if(error.response.status == 404)
                            this.$store.dispatch('addToast', {msg: 'No information found', type: 'error'});
                        else
                            this.$store.dispatch('addToast', {msg: 'An error has occurred on the server side, please contact support', type: 'error'});
                    });
                setTimeout(() => {
                    this.$store.state.is_loading = false;
                }, 500);
            }
        },
        // scroll page to top
        scrollToTop() {
            if(document.getElementById('wrapper'))
                document.getElementById('wrapper').scrollTo({ top: 0, behavior: 'smooth' });
        }
    },
}
</script>
