<template>
    <div style="font-family: Questrial;"class="flex flex-row items-center justify-center w-full">
        <div class="hero min-h-screen bg-base-200 rounded-lg">
            <div class="hero-content flex-col lg:flex-row gap-16 px-12 md:px-16 w-full">
                <div class="relative w-full max-w-[70%] h-full max-h-[600px] group overflow-hidden">
                    <img class="object-cover w-full h-full transition-transform shadow-2xl duration-500 ease-out group-hover:scale-110 cursor-pointer" src="@/assets/img/posts/1-1-684x1024.jpg" alt="" />
                </div>
                <div class="flex flex-col items-center justify-start gap-2 w-full">
                    <p class="text-md font-bold w-full uppercase text-red-500 text-left">MOST POPULAR</p>
                    <p class="text-3xl font-bold w-full text-black text-left">Mental Health: Inspiring Stories of Perseverance and Resilience</p>
                    <p class="text-lg text-gray-700 w-full font-semibold text-justify">As the prevalence of mental health issues increases and affects an ever larger number of</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'LandingPageHero',
}
</script>
