<template>
    <Navbar :use_bg="true" logo_size="small" logo_mobile_size="small" />
    <div class="w-full p-10 sm:14" style="font-family: Questrial;">
        <div class="flex flex-col items-center justify-start w-full bg-base-100 overflow-x-hidden overflow-y-hidden">
            <p class="text-2xl text-gray-800 w-full text-left font-bold md:max-w-[70%]">About Us</p>
            <p class="text-lg text-gray-700 w-full text-justify md:text-left md:max-w-[70%] mt-4">
                Our website was born from a passion to shine a spotlight on the vibrant and diverse talents of Iranian artists from around the globe. Recognizing the challenges many Persian artists face in gaining visibility and connecting with broader audiences, we envisioned a platform that not only provides them with free publicity but also empowers them to narrate their own stories. Through in-depth interviews, we aim to capture the essence of their journey, inspirations, and the cultural richness that influences their work. Our mission is to foster a greater appreciation and understanding of Persian art and culture, creating a community where artists and art enthusiasts can engage, inspire, and support one another.
            </p>

            <div class="divider md:hidden"></div>

            <!-- first manager -->
            <div class="flex flex-col md:flex-row justify-between items-center md:max-w-[70%] w-full md:mt-10 gap-4">
                <div class="flex flex-row flex-shrink-0 items-center justify-center md:max-w-[40%] w-full">
                    <img class="w-full aspect-square object-cover" src="@/assets/img/editor.jpg">
                </div>
                <div class="flex flex-col items-start justify-center gap-4">
                    <p class="text-2xl font-bold text-gray-800 w-full text-left">About the editor</p>
                    
                    <p class="text-lg text-gray-700 w-full text-justify md:text-left">
                        Every individual carries within them an intricate tapestry of stories, each thread woven from experiences, dreams, struggles, and triumphs that are uniquely theirs. I've been blessed to live in several countries and have met so many wonderful people with phenomenal stories.  In sharing our stories, we find common ground, foster empathy, and create a richer, more understanding world. I'm grateful for the opportunity to share mine and equally eager to bear witness to the stories around me, for it's through them that we truly see the beautiful complexity of life.
                    </p>
                </div>
            </div>
        </div>
    </div>
    <Footer logo_size="normal" logo_mobile_size="small" class="p-10"></Footer>
</template>

<script>
    import Navbar from '@/components/navbar.vue';
    import Footer from '@/components/footer.vue';
    import axios from 'axios';
    
    export default {
        name: 'AboutUs',
        components: {
            Navbar,
            Footer,
        },
        mounted() {
            document.title = 'History Of Colors - About Us';
            this.scrollToTop();
            this.refresh_data();
        },
        methods: {
            async refresh_data() {
                await axios.post('/api/v1/data/get/', {type: 'base_data'} , this.$store.state.axios_config).then(response => {
                    this.$store.state.base_data = response.data.base;
                })
                .catch(error => {
                    if((error.response && error.response.status && error.response.status == 404) || (error.request && error.request.status && error.request.status == 404))
                        this.$store.dispatch('addToast', {msg: 'No information found', type: 'error'});
                    else
                        this.$store.dispatch('addToast', {msg: 'An error has occurred on the server side, please contact support', type: 'error'});
                    console.log(error);
                });
            },
            // scroll page to top
            scrollToTop() {
                if(document.getElementById('wrapper'))
                    document.getElementById('wrapper').scrollTo({ top: 0, behavior: 'smooth' });
            },
        },
    }
</script>
