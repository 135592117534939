<template>
    <footer class="mt-8 w-full flex flex-col items-center justify-center bg-[#444444] pt-10 gap-4 text-white" style="font-family: Questrial;" :data-theme="this.$store.state.app_theme" :class="{'dark': this.$store.state.use_dark}">
        <div class="grid grid-cols-1 md:grid-cols-2 w-full md:gap-36">
            <div class="flex flex-col items-start justify-start w-full gap-1">
                <!-- logo -->
                <div class="w-full flex flex-row items-center justify-start">
                    <router-link to="/" class="cursor-pointer">
                        <!-- <Logo class="w-full" logo_size="free" logo_mobile_size="free" :use_black="false" /> -->
                        <Logo :class="{'md:h-auto': logo_size == 'free', 'md:h-24': logo_size == 'normal', 'md:h-20': logo_size == 'small', 'md:h-12': logo_size == 'tiny', 'h-auto': logo_mobile_size == 'free', 'h-24': logo_mobile_size == 'normal', 'h-20': logo_mobile_size == 'small', 'h-12': logo_mobile_size == 'tiny'}" :use_black="false" />
                    </router-link>
                </div>
                <p class="text-lg mt-2 text-justify">History of Colors is an online publication of Iranian Artist Organization LLC based in Irvine, California. The primary mission behind creating this organization is to establish a vibrant platform where Iranian artists and creatives can present themselves, their artwork, and their insights.</p>
            </div>
            <div class="divider md:hidden bg-transparent dark" data-theme="dracula"></div>
            <div class="flex flex-col items-start justify-start w-full gap-1">
                <router-link to="/about-history-of-art/">About History of Colors</router-link>
                <router-link to="/">Careers</router-link>
                <router-link to="/advertise-with-us/">Advertise with Us</router-link>
                <router-link to="/">Contact Us</router-link>

                <!-- subscribe section -->
                <div class="flex flex-col items-center justify-center w-full bg-transparent mt-8" data-theme="light"  style="font-family: Questrial;">
                    <p class="text-base font-bold text-white opacity-80 w-full text-left">Subscribe :</p>
                    <div class="flex flex-col md:flex-row gap-2 w-full items-center justify-center mt-2">
                        <label class="input input-bordered w-full bg-transparent dark flex items-center gap-2" data-theme="dracula">
                            <span v-if="is_loading" class="loading loading-spinner loading-xs"></span>
                            <svg v-else class="w-5 h-5 stroke-current text-white" stroke-width="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7 9L12 12.5L17 9" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M2 17V7C2 5.89543 2.89543 5 4 5H20C21.1046 5 22 5.89543 22 7V17C22 18.1046 21.1046 19 20 19H4C2.89543 19 2 18.1046 2 17Z" stroke-width="1.5"></path></svg>
                            <input :disabled="is_loading" v-model="subscribe_email" type="text" class="w-full bg-transparent dark" placeholder="Enter your email" data-theme="dracula">
                            <button v-show="!is_loading && subscribe_email" class="btn btn-sm btn-ghost btn-square" @click="subscribe_email = ''">
                                <svg class="w-5 h-5 stroke-current text-white/70" stroke-width="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.75827 17.2426L12.0009 12M17.2435 6.75736L12.0009 12M12.0009 12L6.75827 6.75736M12.0009 12L17.2435 17.2426" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                            </button>
                        </label>
                        <button @click="submit_subscriber" class="btn w-full md:w-auto bg-red-600 hover:bg-red-700 border-gray-700 text-white">Subscribe</button>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
    import Logo from '@/components/logo.vue';
    import axios from 'axios';

    export default {
        name: 'Footer',
        props: ['logo_size', 'logo_mobile_size'],
        data() {
            return {
                subscribe_email: '',
                is_loading: false,
            }
        },
        components: {
            Logo,
        },
        methods: {
            validateEmail(_email) {
                return String(_email)
                    .toLowerCase()
                    .match(
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                );
            },
            async submit_subscriber() {
                if(this.is_loading)
                    return;

                // check if email is empty
                if(!this.subscribe_email)
                {
                    this.$store.dispatch('addToast', {msg: 'Enter your email', type: 'error'});
                    return;
                }

                // check email validity
                if(!this.validateEmail(this.subscribe_email))
                {
                    await this.$store.dispatch('addToast', {msg: 'The email entered is not valid', type: 'error'});
                    return;
                }

                this.is_loading = true;

                await axios.post('/api/v1/data/actions/', {type: 'add_subscriber', email: this.subscribe_email} , this.$store.state.axios_config).then(response => {
                        setTimeout(() => {
                            this.$store.dispatch('addToast', {msg: 'Your email has been added', type: 'success'});
                            this.subscribe_email = '';
                            this.is_loading = false;
                        }, 1000);
                    })
                    .catch(error => {
                        if((error.response && error.response.status && error.response.status == 404) || (error.request && error.request.status && error.request.status == 404))
                            this.$store.dispatch('addToast', {msg: 'No information found', type: 'error'});
                        else if((error.response && error.response.status && error.response.status == 302) || (error.request && error.request.status && error.request.status == 302))
                            this.$store.dispatch('addToast', {msg: 'Your email is already registered', type: 'error'});
                        else
                            this.$store.dispatch('addToast', {msg: 'An error has occurred on the server side, please contact support', type: 'error'});
                        if(this.$store.state.is_debug)
                            console.log(error);
                        setTimeout(() => {
                            this.is_loading = false;
                        }, 1000);
                    });
            },
        },
    }
</script>
