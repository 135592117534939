<template>
    <!-- pc table row -->
    <tr style="font-family: Questrial;" v-if="item && !is_mobile">
        <td class="text-center text-xs">{{ index }}</td>
        <td class="text-center text-base">{{ item.person.name }}</td>
        <td class="text-center text-base">{{ item.person.email }}</td>
        <td class="text-center text-base">{{ item.invite_code }}</td>
        <td class="text-center text-base">{{ get_time_from_timestamp(item.create_time) }}</td>
        <td class="text-center text-base">{{ get_time_from_timestamp(item.create_time + 1209600) }}</td>
        <td class="text-center text-base">
            <div class="flex flex-row items-center justify-center gap-1">
                <div class="tooltip mr-1" data-tip="Usage Status: This option shows whether the user has used the invitation code or not">
                    <ProgressCheckIcon v-show="item.is_used" :size="18" class="pointer-events-none text-success" />
                    <ProgressHelperIcon v-show="!item.is_used" :size="18" class="pointer-events-none text-gray-800" />
                </div>
                <div class="tooltip" data-tip="Edit invite code">
                    <button @click="this.$emit('edit_item', 'edit', 'invitation_code', {id: item.id, code: item.invite_code, create_time: item.create_time, is_used: item.is_used})" class="btn btn-square btn-ghost btn-sm">
                        <PencilIcon :size="18" class="pointer-events-none text-info" />
                    </button>
                </div>
                <div class="tooltip" data-tip="Delete invite code">
                    <button @click="this.$emit('delete_item', 'invite_code', this.item.id)" class="btn btn-square btn-ghost btn-sm">
                        <DeleteIcon :size="18" class="pointer-events-none text-error" />
                    </button>
                </div>
            </div>
        </td>
    </tr>

    <!-- mobile card -->
    <div class="flex flex-col w-full items-center justify-center card shadow border rounded-lg p-4 text-xs" style="font-family: Questrial;" v-if="item && is_mobile">
        <div class="flex flex-row w-full items-center justify-center gap-2">
            <div class="flex flex-col items-center justify-center gap-2">
                <!-- name -->
                <div class="flex flex-col items-center w-full justify-center gap-1">
                    <p class="text-gray-500 w-full text-left">Name:</p>
                    <p class="w-full text-left">{{ item.person.name }}</p>
                </div>
                <!-- email -->
                <div class="flex flex-col items-center w-full justify-center gap-1">
                    <p class="text-gray-500 w-full text-left">Email:</p>
                    <p class="w-full text-left">{{ item.person.email }}</p>
                </div>
            </div>
            <!-- divider -->
            <div class="divider divider-horizontal"></div>
            <!-- code -->
            <div class="flex flex-col items-center w-full justify-center gap-1">
                <p class="text-gray-500 w-full text-left">Code:</p>
                <p class="w-full text-left">{{ item.invite_code }}</p>
            </div>
        </div>
        <div class="divider py-1 my-1"></div>
        <div class="flex flex-row w-full items-center justify-center gap-2">
            <!-- creation date -->
            <div class="flex flex-col items-center w-full justify-center gap-1">
                <p class="text-gray-500 w-full text-left">Creation date:</p>
                <p class="w-full text-left">{{ get_time_from_timestamp(item.create_time) }}</p>
            </div>
            <!-- divider -->
            <div class="divider divider-horizontal"></div>
            <!-- expire data -->
            <div class="flex flex-col items-center w-full justify-center gap-1">
                <p class="text-gray-500 w-full text-left">Expire date:</p>
                <p class="w-full text-left">{{ get_time_from_timestamp(item.create_time + 1209600) }}</p>
            </div>
        </div>
        <div class="divider py-1 my-1"></div>
        <p>Actions</p>
        <div class="flex flex-row items-center justify-center gap-2 mt-2">
            <div class="tooltip mr-1" data-tip="Used status">
                <ProgressCheckIcon v-show="item.is_used" :size="18" class="pointer-events-none text-success" />
                <ProgressHelperIcon v-show="!item.is_used" :size="18" class="pointer-events-none text-gray-800" />
            </div>
            <div class="tooltip" data-tip="Edit category">
                <button @click="this.$emit('edit_item', 'edit', 'invitation_code', {id: item.id, code: item.invite_code, create_time: item.create_time, is_used: item.is_used})" class="btn btn-square btn-ghost btn-sm">
                    <PencilIcon :size="18" class="pointer-events-none text-info" />
                </button>
            </div>
            <div class="tooltip" data-tip="Delete category">
                <button @click="this.$emit('delete_item', 'invite_code', this.item.id)" class="btn btn-square btn-ghost btn-sm">
                    <DeleteIcon :size="18" class="pointer-events-none text-error" />
                </button>
            </div>
        </div>
    </div>
</template>

<script>
// icons
import DeleteIcon from 'vue-material-design-icons/Delete.vue';
import ProgressCheckIcon from 'vue-material-design-icons/ProgressCheck.vue';
import ProgressHelperIcon from 'vue-material-design-icons/ProgressHelper.vue';
import PencilIcon from 'vue-material-design-icons/Pencil.vue';

export default {
    name: 'AdminInviteCodeItem',
    emits: ['delete_item', 'edit_item'],
    props: ['index', 'item', 'is_mobile'],
    components: {
        // icons
        DeleteIcon,
        ProgressCheckIcon,
        ProgressHelperIcon,
        PencilIcon,
    },
    methods: {
        // get date time from timestamp
        get_time_from_timestamp(timestamp) {
            return new Date(timestamp * 1000).toLocaleString();
        },
    },
}
</script>
