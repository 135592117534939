<template>
    
    <div style="font-family: Questrial;" class="flex flex-col items-start justify-start w-full gap-4">
        <p class="text-black font-bold text-xl">Latest</p>
        
        <div class="flex flex-col md:flex-row items-start justify-between w-full overflow-hidden">
    
            <div class="flex flex-col items-center justify-start w-full gap-2" v-if="this.$store.state.base_data && this.$store.state.base_data.posts && this.$store.state.base_data.posts.length > 0">
                <template v-for="(item, index) in get_allowed_posts(0, 3)" :key="index">
                    <div class="flex flex-col items-start justify-start w-full" v-if="index == 0">
                        <div class="relative w-full h-full group overflow-hidden">
                            <router-link :to="'/similar/' + item.title_slug + '/' + item.id">
                                <img class="object-cover w-full h-full transition-transform duration-500 ease-out group-hover:scale-110 cursor-pointer" :src="this.$store.state.base_url + item.personal_image" :alt="item.slug + '-image'" />
                            </router-link>
                        </div>
                        <router-link :to="'/category/' + item.content_category.slug" v-if="item.content_category">
                            <p class="text-sm font-bold text-left uppercase text-red-500 mt-8">{{ item.content_category.name }}</p>
                        </router-link>
                        <router-link :to="'/artcategory/' + item.artist_category.slug" v-if="item.artist_category">
                            <p class="text-sm font-bold text-left uppercase text-blue-400">{{ item.artist_category.name }}</p>
                        </router-link>
                        <p v-else class="text-sm font-bold text-left uppercase text-blue-400">{{ item.custom_artist_category }}</p>
                        <p class="text-3xl font-bold text-left text-black mt-2">{{ item.title }}</p>
                        <p class="text-lg text-gray-700 text-left font-semibold line-clamp-3">{{ item.short_description }}</p>
                    </div>
    
                    <div class="divider" v-if="index == 1"></div>
    
                    <div class="flex flex-col md:flex-row items-center justify-start gap-6" v-if="index == 1">
                        <div class="w-full relative h-60 md:max-w-[45%] group overflow-hidden">
                            <router-link :to="'/similar/' + item.title_slug + '/' + item.id">
                                <img class="object-cover w-full h-full transition-transform duration-500 ease-out group-hover:scale-110 cursor-pointer" :src="this.$store.state.base_url + item.personal_image" :alt="item.slug + '-image'" />
                            </router-link>
                        </div>
                        <div class="flex flex-col items-start justify-center w-full">
                            <router-link :to="'/category/' + item.content_category.slug" class="text-left w-full" v-if="item.content_category">
                                <p class="text-sm font-bold uppercase text-red-500">{{ item.content_category.name }}</p>
                            </router-link>
                            <router-link :to="'/artcategory/' + item.artist_category.slug" v-if="item.artist_category">
                                <p class="text-sm font-bold text-left uppercase text-blue-400">{{ item.artist_category.name }}</p>
                            </router-link>
                            <p v-else class="text-sm font-bold text-left uppercase text-blue-400">{{ item.custom_artist_category }}</p>
                            <p class="text-xl font-bold w-full text-black text-left mt-2">{{ item.title }}</p>
                            <p class="text-base text-gray-700 w-full font-semibold text-left line-clamp-3">{{ item.short_description }}</p>
                        </div>
                    </div>
    
                    <div class="divider" v-if="index == 2"></div>
    
                    <div class="flex flex-col md:flex-row items-center justify-start gap-6" v-if="index == 2">
                        <div class="w-full relative h-60 md:max-w-[45%] group overflow-hidden">
                            <router-link :to="'/similar/' + item.title_slug + '/' + item.id">
                                <img class="object-cover w-full h-full transition-transform duration-500 ease-out group-hover:scale-110 cursor-pointer" :src="this.$store.state.base_url + item.personal_image" :alt="item.slug + '-image'" />
                            </router-link>
                        </div>
                        <div class="flex flex-col items-start justify-center w-full">
                            <router-link :to="'/category/' + item.content_category.slug" v-if="item.content_category">
                                <p class="text-sm font-bold w-full uppercase text-red-500 text-left">{{ item.content_category.name }}</p>
                            </router-link>
                            <router-link :to="'/artcategory/' + item.artist_category.slug" v-if="item.artist_category">
                                <p class="text-sm font-bold text-left uppercase text-blue-400">{{ item.artist_category.name }}</p>
                            </router-link>
                            <p v-else class="text-sm font-bold text-left uppercase text-blue-400">{{ item.custom_artist_category }}</p>
                            <p class="text-xl font-bold w-full text-black text-left mt-2">{{ item.title }}</p>
                            <p class="text-base text-gray-700 w-full font-semibold text-left line-clamp-3">{{ item.short_description }}</p>
                        </div>
                    </div>
                </template>
            </div>
    
            <div class="divider md:divider-horizontal" v-if="get_allowed_posts(4, 7).length > 0"></div>
    
            <div class="flex flex-col items-start justify-start w-full md:max-w-[25%] gap-2">
                <template v-for="(item, index) in get_allowed_posts(4, 7)" :key="index">
                    <div class="flex flex-col items-center justify-center gap-6 w-full">
                        <div class="w-full relative h-60 group overflow-hidden">
                            <!-- post image -->
                            <router-link :to="'/similar/' + item.title_slug + '/' + item.id">
                                <img class="object-cover w-full h-full transition-transform duration-500 ease-out group-hover:scale-110 cursor-pointer" :src="this.$store.state.base_url + item.personal_image" :alt="item.slug + '-image'" />
                            </router-link>
                        </div>
                        <div class="flex flex-col items-center justify-start gap-2 w-full">
                            <!-- category -->
                            <router-link :to="'/category/' + item.content_category.slug" v-if="item.content_category">
                                <p class="text-sm font-bold text-left uppercase text-red-500 mt-8">{{ item.content_category.name }}</p>
                            </router-link>
                            <!-- art category -->
                            <router-link :to="'/artcategory/' + item.artist_category.slug" v-if="item.artist_category">
                                <p class="text-sm font-bold text-left uppercase text-blue-400">{{ item.artist_category.name }}</p>
                            </router-link>
                            <p v-else class="text-sm font-bold text-left uppercase text-blue-400">{{ item.custom_artist_category }}</p>
                            <p class="text-3xl font-bold text-left text-black mt-2">{{ item.title }}</p>
                            <p class="text-lg text-gray-700 text-left font-semibold line-clamp-3">{{ item.short_description }}</p>
                        </div>
                    </div>
    
                    <div class="divider" v-if="index < 7"></div>

                </template>
            </div>
    
            <div class="divider md:divider-horizontal" v-if="get_allowed_posts(8, 15).length > 0"></div>
    
            <div class="flex flex-col items-start justify-start w-full md:max-w-[25%] gap-2">
                
                <template v-for="(item, index) in get_allowed_posts(8, 15)" :key="index">
                    <div class="flex flex-col items-center justify-start w-full gap-1">
                        <!-- category -->
                        <router-link :to="'/category/' + item.content_category.slug" class="w-full" v-if="item.content_category">
                            <p class="text-sm font-bold text-left w-full uppercase text-red-500 mt-8">{{ item.content_category.name }}</p>
                        </router-link>
                        <!-- art category -->
                        <router-link :to="'/artcategory/' + item.artist_category.slug" class="w-full" v-if="item.artist_category">
                            <p class="text-sm font-bold w-full text-left uppercase text-blue-400">{{ item.artist_category.name }}</p>
                        </router-link>
                        <p v-else class="text-sm font-bold text-left w-full uppercase text-blue-400">{{ item.custom_artist_category }}</p>
                        <div class="flex flex-row items-start justify-between gap-2 w-full">
                            <p class="text-lg w-[calc(100%-115px)] font-bold text-left text-wrap text-black">{{ item.title }}</p>
                            <div class="flex flex-row items-center justify-center h-full w-fit">
                                <div class="relative w-28 h-28 group overflow-hidden">
                                    <!-- post image -->
                                    <router-link :to="'/similar/' + item.title_slug + '/' + item.id">
                                        <img class="object-cover w-full h-full transition-transform duration-500 ease-out group-hover:scale-110 cursor-pointer" :src="this.$store.state.base_url + item.personal_image" :alt="item.slug + '-image'" />
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </div>
    
                    <div class="divider" v-if="index < 15"></div>

                </template>

                <template v-for="(item, index) in get_allowed_posts(15, 16)" :key="index">
                    <div class="divider"></div>
    
                    <div class="flex flex-col items-center justify-center gap-6">
                        <div class="w-full relative h-60 group overflow-hidden">
                            <!-- post image -->
                            <router-link :to="'/similar/' + item.title_slug + '/' + item.id">
                                <img class="object-cover w-full h-full transition-transform duration-500 ease-out group-hover:scale-110 cursor-pointer" :src="this.$store.state.base_url + item.personal_image" :alt="item.slug + '-image'" />
                            </router-link>
                        </div>
                        <div class="flex flex-col items-center justify-start gap-2 w-full">
                            <!-- category -->
                            <router-link :to="'/category/' + item.content_category.slug" class="w-full" v-if="item.content_category">
                                <p class="text-sm font-bold text-left w-full uppercase text-red-500 mt-8">{{ item.content_category.name }}</p>
                            </router-link>
                            <!-- art category -->
                            <router-link :to="'/artcategory/' + item.artist_category.slug" class="w-full" v-if="item.artist_category">
                                <p class="text-sm font-bold w-full text-left uppercase text-blue-400">{{ item.artist_category.name }}</p>
                            </router-link>
                            <p v-else class="text-sm font-bold text-left w-full uppercase text-blue-400">{{ item.custom_artist_category }}</p>
                            <p class="text-xl w-full font-bold text-left text-black">{{ item.title }}</p>
                            <p class="text-base text-gray-700 w-full font-semibold text-left">{{ item.short_description }}</p>
                        </div>
                    </div>
                </template>

            </div>
    
        </div>
    </div>

</template>

<script>
    export default {
        name: 'LandingPageNewPosts',
        methods: {
            get_allowed_posts(start, end) {
                let posts = [];
                if(this.$store.state.base_data && this.$store.state.base_data.posts && this.$store.state.base_data.posts.length > 0)
                {
                    posts = this.$store.state.base_data.posts.filter(p=> p.publish_status == 1)
                }

                if(posts && posts.length > 0)
                    posts = posts.slice(start, end);
                return posts;
            },
        },
    }
</script>
