<template>
    <!-- pc table row -->
    <tr style="font-family: Questrial;" v-if="item && !is_mobile">
        <td class="text-center text-md">{{ index }}</td>
        <td class="text-center text-base">{{ item.name }}</td>
        <td class="text-center text-base">{{ item.slug }}</td>
        <td class="text-center text-base">
            <div class="flex flex-row items-center justify-center gap-1">
                <div class="tooltip" data-tip="Edit artist category">
                    <button @click="this.$emit('edit_item', 'edit', 'artist_category', {id: item.id, name: item.name})" class="btn btn-square btn-ghost btn-sm">
                        <PencilIcon :size="18" class="pointer-events-none text-info" />
                    </button>
                </div>
                <div class="tooltip" data-tip="Delete artist category">
                    <button @click="this.$emit('delete_item', 'artist_category', item.id)" class="btn btn-square btn-ghost btn-sm">
                        <DeleteIcon :size="18" class="pointer-events-none text-error" />
                    </button>
                </div>
            </div>
        </td>
    </tr>

    <!-- mobile card -->
    <div class="flex flex-row w-full items-center justify-between card shadow border rounded-lg p-4 text-xs" style="font-family: Questrial;" v-if="item && is_mobile">
        <div class="flex flex-row w-full items-center justify-start gap-2">
            <!-- name -->
            <div class="flex flex-col items-start justify-center gap-1">
                <p class="text-gray-500 text-left">Name:</p>
                <p class="text-left whitespace-nowrap">{{ item.name }}</p>
            </div>
            <!-- divider -->
            <div class="divider divider-horizontal"></div>
            <!-- slug -->
            <div class="flex flex-col items-start justify-center gap-1">
                <p class="text-gray-500 text-left">Url Name:</p>
                <p class="text-left whitespace-nowrap">{{ item.slug }}</p>
            </div>
        </div>

        <div class="divider divider-horizontal py-1 my-1"></div>

        <!-- buttons -->
        <div class="flex flex-row items-center justify-end w-full">
            <div class="flex flex-col items-center justify-center gap-2">
                <p>Actions</p>
                <div class="flex flex-row items-center justify-center gap-2 mt-2">
                    <div class="tooltip" data-tip="Edit artist category">
                        <button @click="this.$emit('edit_item', 'edit', 'artist_category', {id: item.id, name: item.name})" class="btn btn-square btn-ghost btn-sm">
                            <PencilIcon :size="18" class="pointer-events-none text-info" />
                        </button>
                    </div>
                    <div class="tooltip" data-tip="Delete artist category">
                        <button @click="this.$emit('delete_item', 'artist_category', item.id)" class="btn btn-square btn-ghost btn-sm">
                            <DeleteIcon :size="18" class="pointer-events-none text-error" />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// icons
import DeleteIcon from 'vue-material-design-icons/Delete.vue';
import PencilIcon from 'vue-material-design-icons/Pencil.vue';

export default {
    name: 'AdminArtistCategoryItem',
    emits: ['delete_item', 'edit_item'],
    props: ['index', 'item', 'is_mobile'],
    components: {
        // icons
        DeleteIcon,
        PencilIcon,
    },
}
</script>
