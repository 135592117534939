<template>
    <div class="flex flex-row items-center justify-start">
        <!-- pc -->
        <img class="h-full object-scale-down" v-if="use_black" src="@/assets/img/logo/logo-black.png" alt="History of colors black logo">
        <img class="h-full object-scale-down" v-else src="@/assets/img/logo/logo-white.png" alt="History of colors white logo">
    </div>
</template>

<script>
export default {
    name: 'Logo',
    props: ['size', 'mobile_size', 'use_black'],
}
</script>
