<template>

    <div style="font-family: Questrial;" class="flex flex-col items-start justify-between w-full overflow-hidden">

        <p class="text-black font-bold text-xl">Podcasts</p>

        <div class="w-full flex flex-col md:flex-row items-start justify-between gap-6 mt-10">

            <div class="flex flex-col items-center justify-center gap-6 basis-1/4">
                <div class="w-full relative h-60 group overflow-hidden">
                    <img class="object-cover w-full h-full transition-transform duration-500 ease-out group-hover:scale-110 cursor-pointer" src="@/assets/img/posts/1-4-1-e1707199406150-768x579.jpeg" alt="" />
                </div>
                <div class="flex flex-col items-center justify-start gap-2 w-full">
                    <p class="text-sm font-bold w-full uppercase text-red-500 text-left">LEADERSHIP DEVELOPMENT</p>
                    <p class="text-xl font-bold w-full text-black text-left">How did you find your purpose?</p>
                    <p class="text-base text-gray-700 w-full font-semibold text-left">Core to our mission is helping our audience and community reach their full potential and</p>
                </div>
            </div>

            <div class="flex flex-col items-center justify-center gap-6 basis-1/4">
                <div class="w-full relative h-60 group overflow-hidden">
                    <img class="object-cover w-full h-full transition-transform duration-500 ease-out group-hover:scale-110 cursor-pointer" src="@/assets/img/posts/2-7-768x512.jpg" alt="" />
                </div>
                <div class="flex flex-col items-center justify-start gap-2 w-full">
                    <p class="text-sm font-bold w-full uppercase text-red-500 text-left">PRODUCTIVITY</p>
                    <p class="text-xl font-bold w-full text-black text-left">Perspectives on Staying Creative</p>
                    <p class="text-base text-gray-700 w-full font-semibold text-left">We’re beyond fortunate to have built a community of some of the most creative artists,</p>
                </div>
            </div>

            <div class="flex flex-col items-center justify-center gap-6 basis-1/4">
                <div class="w-full relative h-60 group overflow-hidden">
                    <img class="object-cover w-full h-full transition-transform duration-500 ease-out group-hover:scale-110 cursor-pointer" src="@/assets/img/posts/2-768x768.jpg" alt="" />
                </div>
                <div class="flex flex-col items-center justify-start gap-2 w-full">
                    <p class="text-sm font-bold w-full uppercase text-red-500 text-left">RESILIENCE</p>
                    <p class="text-xl font-bold w-full text-black text-left">Betting on the Brightside: Developing and Fostering Optimism</p>
                    <p class="text-base text-gray-700 w-full font-semibold text-left">Optimism is like magic – it has the power to make the impossible a reality</p>
                </div>
            </div>

            <div class="flex flex-col items-center justify-center gap-6 basis-1/4">
                <div class="w-full relative h-60 group overflow-hidden">
                    <img class="object-cover w-full h-full transition-transform duration-500 ease-out group-hover:scale-110 cursor-pointer" src="@/assets/img/posts/1-5-682x1024.jpeg" alt="" />
                </div>
                <div class="flex flex-col items-center justify-start gap-2 w-full">
                    <p class="text-sm font-bold w-full uppercase text-red-500 text-left">CONFIDENCE</p>
                    <p class="text-xl font-bold w-full text-black text-left">Kicking Imposter Syndrome to the Curb</p>
                    <p class="text-base text-gray-700 w-full font-semibold text-left">This is the year to kick the pesky imposter syndrome to the curb and move</p>
                </div>
            </div>

        </div>

    </div>

</template>

<script>
    export default {
        name: 'LandingPagePodcast',
    }
</script>
