<template>
    <!-- pc table row -->
    <tr style="font-family: Questrial;" v-if="item && !is_mobile">
        <td class="text-center text-md">{{ index }}</td>
        <td class="text-center text-base whitespace-nowrap">{{ item.name }}</td>
        <td class="text-center text-base whitespace-nowrap">{{ item.company_name }}</td>
        <td class="text-center text-base whitespace-nowrap">{{ item.phone }}</td>
        <td class="text-center text-base">
            <a :href="'mailto:' + item.email">{{ item.email }}</a>
        </td>
        <td class="text-center text-base">
            <p v-if="!item.website">-</p>
            <a :href="get_true_url(item.website)" target="_blank" v-if="item.website">{{ item.website }}</a>
        </td>
        <td class="text-left text-base">{{ item.description }}</td>
        <td class="hidden text-center text-base">
            <div class="flex flex-col md:flex-row items-center justify-center gap-1">
                <div class="flex flex-row items-center justify-center gap-1">
                    <div class="tooltip" data-tip="Send the first email">
                        <button @click="send_email('first_email')" class="btn btn-square btn-ghost btn-sm flex flex-row items-center justify-center relative">
                            <EmailIcon :size="18" class="pointer-events-none text-gray-500" :class="{'text-success': item.email_1_is_send}" />
                            <span class="absolute -top-1 right-0 text-xs font-bold text-gray-500" :class="{'text-success': item.email_1_is_send}">1</span>
                        </button>
                    </div>
                    <div class="tooltip" data-tip="Send the second email">
                        <button @click="send_email('second_email')" class="btn btn-square btn-ghost btn-sm flex flex-row items-center justify-center relative">
                            <EmailIcon :size="18" class="pointer-events-none text-gray-500" :class="{'text-primary': item.email_2_is_send}" />
                            <span class="absolute -top-1 right-0 text-xs font-bold text-gray-500" :class="{'text-primary': item.email_2_is_send}">2</span>
                        </button>
                    </div>
                </div>
                <div class="flex flex-row items-center justify-center gap-1">
                    <div class="tooltip" data-tip="Change information check status">
                        <button @click="change_is_checked" class="btn btn-square btn-ghost btn-sm">
                            <EyeCheckOutlineIcon v-if="item.is_checked" :size="18" class="pointer-events-none text-success" />
                            <EyeOutlineIcon v-else :size="18" class="pointer-events-none" />
                        </button>
                    </div>
                </div>
                <div class="tooltip" data-tip="Delete information">
                    <button @click="this.$emit('delete_item', 'person', this.item.id)" class="btn btn-square btn-ghost btn-sm">
                        <DeleteIcon :size="18" class="pointer-events-none text-error" />
                    </button>
                </div>
            </div>
        </td>
    </tr>
    <!-- mobile card -->
    <div class="flex flex-col w-full items-center justify-center card shadow border rounded-lg p-4 text-xs" style="font-family: Questrial;" v-if="item && is_mobile">
        <div class="flex flex-row w-full items-center justify-center gap-2">
            <!-- name -->
            <div class="flex flex-col items-center w-full justify-center gap-1">
                <p class="text-gray-500 w-full text-left">Name:</p>
                <p class="w-full text-left">{{ item.name }}</p>
            </div>
            <!-- divider -->
            <div class="divider divider-horizontal"></div>
            <!-- company name -->
            <div class="flex flex-col items-center w-full justify-center gap-1">
                <p class="text-gray-500 w-full text-left">Name of Company</p>
                <p class="w-full text-left">{{ item.company_name }}</p>
            </div>
        </div>
        <div class="divider py-1 my-1"></div>
        <div class="flex flex-row w-full items-center justify-center gap-2">
            <!-- phone -->
            <div class="flex flex-col items-center w-full justify-center gap-1">
                <p class="text-gray-500 w-full text-left">Phone:</p>
                <p class="w-full text-left">{{ item.phone }}</p>
            </div>
            <!-- divider -->
            <div class="divider divider-horizontal"></div>
            <!-- email -->
            <div class="flex flex-col items-center w-full justify-center gap-1">
                <p class="text-gray-500 w-full text-left">Email</p>
                <p class="w-full text-left">{{ item.email }}</p>
            </div>
        </div>
        <div class="divider py-1 my-1"></div>
        <!-- website -->
        <div class="flex flex-col items-center w-full justify-center gap-1">
            <p class="text-gray-500 w-full text-left">Website:</p>
            <p class="w-full text-left">{{ item.website }}</p>
        </div>
        <div class="divider py-1 my-1"></div>
        <!-- description -->
        <div class="flex flex-col items-center w-full justify-center gap-1">
            <p class="text-gray-500 w-full text-left">Description:</p>
            <p class="w-full text-left">{{ item.description }}</p>
        </div>
        <p class="hidden">Actions</p>
        <div class="hidden flex-row items-center justify-center gap-2 mt-2">
            <div class="tooltip" data-tip="Send the first email">
                <button @click="send_email('first_email')" class="btn btn-square btn-ghost btn-sm flex flex-row items-center justify-center relative">
                    <EmailIcon :size="18" class="pointer-events-none text-gray-500" :class="{'text-success': item.email_1_is_send}" />
                    <span class="absolute -top-1 right-0 text-xs font-bold text-gray-500" :class="{'text-success': item.email_1_is_send}">1</span>
                </button>
            </div>
            <div class="tooltip" data-tip="Send the second email">
                <button @click="send_email('second_email')" class="btn btn-square btn-ghost btn-sm flex flex-row items-center justify-center relative">
                    <EmailIcon :size="18" class="pointer-events-none text-gray-500" :class="{'text-primary': item.email_2_is_send}" />
                    <span class="absolute -top-1 right-0 text-xs font-bold text-gray-500" :class="{'text-primary': item.email_2_is_send}">2</span>
                </button>
            </div>
            <div class="tooltip" data-tip="Change information check status">
                <button @click="change_is_checked" class="btn btn-square btn-ghost btn-sm">
                    <EyeCheckOutlineIcon v-if="item.is_checked" :size="18" class="pointer-events-none text-success" />
                    <EyeOutlineIcon v-else :size="18" class="pointer-events-none" />
                </button>
            </div>
            <div class="tooltip" data-tip="Delete information">
                <button @click="this.$emit('delete_item', 'person', this.item.id)" class="btn btn-square btn-ghost btn-sm">
                    <DeleteIcon :size="18" class="pointer-events-none text-error" />
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
// icons
import DeleteIcon from 'vue-material-design-icons/Delete.vue';
import EyeOutlineIcon from 'vue-material-design-icons/EyeOutline.vue';
import EyeCheckOutlineIcon from 'vue-material-design-icons/EyeCheckOutline.vue';
import EmailBoxIcon from 'vue-material-design-icons/EmailBox.vue';
import EmailIcon from 'vue-material-design-icons/Email.vue';

export default {
    name: 'AdminAdvertiseItem',
    emits: [],
    props: ['index', 'item', 'is_mobile'],
    components: {
        // icons
        DeleteIcon,
        EyeOutlineIcon,
        EyeCheckOutlineIcon,
        EmailBoxIcon,
        EmailIcon,
    },
    methods: {
        get_true_url(value) {
            if(value)
                return value.startsWith("http://") || value.startsWith("https://") ? value : 'http://' + value;
            return '';
        }
    },
}
</script>
