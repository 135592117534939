<template>
    <div class="flex flex-col items-start justify-start w-full gap-4">
        <p class="text-gray-700 text-left w-full text-2xl font-bold">Latest</p>
        <template v-for="(item, index) in posts" :key="index" v-if="posts">
            <div class="flex flex-col items-start justify-center">
                <router-link class="w-full" :to="'/post/' + item.slug">
                    <img class="object-cover w-full aspect-square h-full transition-transform duration-500 ease-out group-hover:scale-110 cursor-pointer" :src="this.$store.state.base_url + item.personal_image" :alt="item.slug + '-image'" />
                </router-link>
                <router-link :to="'/category/' + item.content_category.slug" class="mt-2" v-if="item.content_category">
                    <p class="text-sm font-bold text-left uppercase text-red-500">{{ item.content_category.name }}</p>
                </router-link>
                <router-link :to="'/artcategory/' + item.artist_category.slug" v-if="item.artist_category">
                    <p class="text-sm font-bold text-left uppercase text-blue-400">{{ item.artist_category.name }}</p>
                </router-link>
                <p v-else class="text-sm font-bold text-left uppercase text-blue-400">{{ item.custom_artist_category }}</p>
                <p class="text-lg text-gray-700 text-left font-semibold line-clamp-3 text-wrap">{{ item.title }}</p>
                <!-- <p class="text-lg text-gray-700 text-left font-semibold line-clamp-3 mt-2 text-wrap">{{ item.short_description }}</p> -->
            </div>
            <div class="divider" v-if="index < posts.length-1"></div>
        </template>
    </div>    
</template>

<script>
export default {
    name: 'NewPostsSidebar',
    props: ['posts'],
}
</script>
