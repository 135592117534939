<template>
    <!-- pc -->
    <div style="font-family: Questrial;" class="hidden md:grid grid-cols-3 w-full gap-3 relative h-screen">
        <!-- :style="{'background-color': `rgb(31 41 55 / ${bg_opacity_value})`}" -->
        <div style="font-size: 3em; background: linear-gradient(180deg, rgba(2,0,36,0.85) 0%, rgba(2,0,36,0) 50%, rgba(2,0,36,0.85) 100%);" class="absolute hidden flex-col items-center pb-20 px-8 justify-end top-0 bottom-0 right-0 left-0 w-full h-full">
            <h1 class="font-extrabold text-white w-full text-center leading-loose uppercase" style="font-size: 1.3em;">Inspiring Stories Of Iranian Artists</h1>
            <h2 class="text-white w-full text-center leading-loose mt-0" style="font-size: 0.46em;">Amazing and talented artists that skillfully blend centuries of rich cultural heritage with contemporary themes</h2>
        </div>

        <div style="font-family: Questrial; background: linear-gradient(180deg, rgba(2,0,36,0.85) 0%, rgba(2,0,36,0) 50%, rgba(2,0,36,0.85) 100%);" class="absolute hidden flex-col items-center pb-20 px-8 justify-end top-0 bottom-0 right-0 left-0 w-full h-full">
            <svg class="w-[95%] h-auto text-white fill-current stroke-current" viewBox="0 0 2560 350" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;">
                <g transform="matrix(1,0,0,1,0,-473.051)">
                    <g transform="matrix(1,0,0,1,-954.358,24.0856)">
                        <g transform="matrix(125,0,0,125,3493.61,626.771)">
                        </g>
                        <text x="969.984px" y="626.771px" style="font-family: Questrial; font-size:125px;">INSPIRING ST<tspan x="1801.16px 1898.39px " y="626.771px 626.771px ">OR</tspan>IES OF IRANIAN <tspan x="2968.03px 3051.41px 3139.42px 3215.77px " y="626.771px 626.771px 626.771px 626.771px ">ARTI</tspan>STS</text>
                    </g>
                    <g transform="matrix(1,0,0,1,-1021.91,-184.04)">
                        <g transform="matrix(50,0,0,50,3496.55,909.828)">
                        </g>
                        <text x="1109.27px" y="909.828px" style="font-family: Questrial; font-size:50px;">Amazing and talented artists that skillfully blend centuries of rich cultural heritage with contemporary themes</text>
                    </g>
                </g>
            </svg>
        </div>

        <div style="background: linear-gradient(180deg, rgba(2,0,36,0.85) 0%, rgba(2,0,36,0) 50%, rgba(2,0,36,0.85) 100%);" class="absolute hidden md:flex flex-col items-center pb-10 px-8 justify-end top-0 bottom-0 right-0 left-0 w-full h-full">
            <img class="w-full max-w-[90%] h-auto" src="@/assets/img/title/title-pc-white.png" alt="title">
        </div>
        
        <!-- right -->
        <div class="grid grid-rows-2 gap-3 max-h-screen">
            <div class="grid grid-cols-2 gap-3 max-h-[calc(100vh / 2)]">
                <div class="max-h-[calc(100vh / 2)]" v-if="this.$store.state.base_data && this.$store.state.base_data.landing_page_cards && this.$store.state.base_data.landing_page_cards[0].image">
                    <img class="object-cover w-full h-full" :src="this.$store.state.base_url + this.$store.state.base_data.landing_page_cards[0].image" />
                </div>
                <div class="max-h-[calc(100vh / 2)]" v-if="this.$store.state.base_data && this.$store.state.base_data.landing_page_cards && this.$store.state.base_data.landing_page_cards[1].image">
                    <img class="object-cover w-full h-full" :src="this.$store.state.base_url + this.$store.state.base_data.landing_page_cards[1].image" />
                </div>
            </div>
            <div class="max-h-[calc(100vh / 2)]" v-if="this.$store.state.base_data && this.$store.state.base_data.landing_page_cards && this.$store.state.base_data.landing_page_cards[2].image">
                <img class="object-cover w-full h-full" :src="this.$store.state.base_url + this.$store.state.base_data.landing_page_cards[2].image" />
            </div>
        </div>
        
        <!-- center -->
        <div class="max-h-screen h-full w-full" v-if="this.$store.state.base_data && this.$store.state.base_data.landing_page_cards && this.$store.state.base_data.landing_page_cards[3].image">
            <img class="object-cover w-full h-full" :src="this.$store.state.base_url + this.$store.state.base_data.landing_page_cards[3].image" />
        </div>
        
        <!-- left -->
        <div class="grid grid-rows-3 gap-3 max-h-screen">
            <div class="max-h-[calc(100vh / 3)]" v-if="this.$store.state.base_data && this.$store.state.base_data.landing_page_cards && this.$store.state.base_data.landing_page_cards[4].image">
                <img class="object-cover w-full h-full" :src="this.$store.state.base_url + this.$store.state.base_data.landing_page_cards[4].image" />
            </div>
            <div class="max-h-[calc(100vh / 3)]" v-if="this.$store.state.base_data && this.$store.state.base_data.landing_page_cards && this.$store.state.base_data.landing_page_cards[5].image">
                <img class="object-cover w-full h-full" :src="this.$store.state.base_url + this.$store.state.base_data.landing_page_cards[5].image" />
            </div>
            <div class="max-h-[calc(100vh / 3)]" v-if="this.$store.state.base_data && this.$store.state.base_data.landing_page_cards && this.$store.state.base_data.landing_page_cards[6].image">
                <img class="object-cover w-full h-full" :src="this.$store.state.base_url + this.$store.state.base_data.landing_page_cards[6].image" />
            </div>
        </div>
    </div>

    <!-- mobile -->
    <div style="font-family: Questrial;" class="grid grid-rows-3 md:hidden w-full gap-3 relative h-screen">
        <!-- :style="{'background-color': `rgb(31 41 55 / ${bg_opacity_value})`}" -->
        <div style="background: linear-gradient(180deg, rgba(2,0,36,0.85) 0%, rgba(2,0,36,0) 50%, rgba(2,0,36,0.85) 100%);" class="absolute hidden flex-col items-center pb-16 justify-end top-0 bottom-0 right-0 left-0 w-full h-full">
            <h1 class="font-extrabold text-white text-center text-4xl md:text-7xl leading-loose">Inspiring Stories Of Iranian Artists</h1>
            <h2 class="text-white w-full text-center text-xl leading-loose mt-4 px-4">Amazing and talented artists that skillfully blend centuries of rich cultural heritage with contemporary themes</h2>
        </div>

        <div style="font-family: Questrial; background: linear-gradient(180deg, rgba(2,0,36,0.85) 0%, rgba(2,0,36,0) 50%, rgba(2,0,36,0.85) 100%);" class="absolute hidden flex-col items-center pb-20 px-8 justify-end top-0 bottom-0 right-0 left-0 w-full h-full">
            <svg class="w-[95%] h-auto text-white fill-current stroke-current" viewBox="0 0 2560 350" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;">
                <g transform="matrix(1,0,0,1,0,-473.051)">
                    <g transform="matrix(1,0,0,1,-954.358,24.0856)">
                        <g transform="matrix(125,0,0,125,3493.61,626.771)">
                        </g>
                        <text x="969.984px" y="626.771px" style="font-family: Questrial; font-size:125px;">INSPIRING ST<tspan x="1801.16px 1898.39px " y="626.771px 626.771px ">OR</tspan>IES OF IRANIAN <tspan x="2968.03px 3051.41px 3139.42px 3215.77px " y="626.771px 626.771px 626.771px 626.771px ">ARTI</tspan>STS</text>
                    </g>
                    <g transform="matrix(1,0,0,1,-1021.91,-184.04)">
                        <g transform="matrix(50,0,0,50,3496.55,909.828)">
                        </g>
                        <text x="1109.27px" y="909.828px" style="font-family: Questrial; font-size:50px;">Amazing and talented artists that skillfully blend centuries of rich cultural heritage with contemporary themes</text>
                    </g>
                </g>
            </svg>
        </div>

        <div style="background: linear-gradient(180deg, rgba(2,0,36,0.85) 0%, rgba(2,0,36,0) 50%, rgba(2,0,36,0.85) 100%);" class="absolute flex md:hidden flex-col items-center pb-10 px-8 justify-end top-0 bottom-0 right-0 left-0 w-full h-full">
            <img class="w-full h-auto" src="@/assets/img/title/title-mobile-white.png" alt="title">
        </div>
        
        <!-- row 1 -->
        <div class="flex flex-row w-full h-full gap-3">
            <div class="flex flex-col w-[60%] gap-3">
                <div class="w-full max-h-[calc(50%-6px)] h-full" v-if="this.$store.state.base_data && this.$store.state.base_data.landing_page_cards && this.$store.state.base_data.landing_page_cards[0].image">
                    <img class="object-cover w-full h-full" :src="this.$store.state.base_url + this.$store.state.base_data.landing_page_cards[0].image" />
                </div>
                <div class="w-full max-h-[calc(50%-6px)] h-full" v-if="this.$store.state.base_data && this.$store.state.base_data.landing_page_cards && this.$store.state.base_data.landing_page_cards[1].image">
                    <img class="object-cover w-full h-full" :src="this.$store.state.base_url + this.$store.state.base_data.landing_page_cards[1].image" />
                </div>
            </div>
            <div class="w-[40%]" v-if="this.$store.state.base_data && this.$store.state.base_data.landing_page_cards && this.$store.state.base_data.landing_page_cards[2].image">
                <img class="object-cover w-full h-full" :src="this.$store.state.base_url + this.$store.state.base_data.landing_page_cards[2].image" />
            </div>
        </div>
        
        <!-- row 2 -->
        <div v-if="this.$store.state.base_data && this.$store.state.base_data.landing_page_cards && this.$store.state.base_data.landing_page_cards[3].image">
            <img class="object-cover w-full h-full" :src="this.$store.state.base_url + this.$store.state.base_data.landing_page_cards[3].image" />
        </div>
        
        <!-- row 3 -->
        <div class="flex flex-row w-full h-full gap-3">
            <div class="w-[40%]" v-if="this.$store.state.base_data && this.$store.state.base_data.landing_page_cards && this.$store.state.base_data.landing_page_cards[6].image">
                <img class="object-cover w-full h-full" :src="this.$store.state.base_url + this.$store.state.base_data.landing_page_cards[6].image" />
            </div>
            <div class="flex flex-col w-[60%] gap-3">
                <div class="w-full max-h-[calc(50%-6px)] h-full" v-if="this.$store.state.base_data && this.$store.state.base_data.landing_page_cards && this.$store.state.base_data.landing_page_cards[4].image">
                    <img class="object-cover w-full h-full" :src="this.$store.state.base_url + this.$store.state.base_data.landing_page_cards[4].image" />
                </div>
                <div class="w-full max-h-[calc(50%-6px)] h-full" v-if="this.$store.state.base_data && this.$store.state.base_data.landing_page_cards && this.$store.state.base_data.landing_page_cards[5].image">
                    <img class="object-cover w-full h-full" :src="this.$store.state.base_url + this.$store.state.base_data.landing_page_cards[5].image" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'LandingPageCards',
        data() {
            return {
                bg_opacity_value: 0.2,
                full_h: 'h-[800px]',
                max_h: 'max-h-[800px]',
                half_h: 'h-[400px]',
                max_half_h: 'max-h-[400px]',
                max_h_1_of_3: 'max-h-[266px]',
            }
        },
    }
</script>
