<template>
    <!-- pc table row -->
    <tr style="font-family: Questrial;" v-if="item && !is_mobile">
        <td class="text-center text-md">{{ index }}</td>
        <td class="text-center text-base">{{ item.category ? item.category.name : '-' }}</td>
        <td class="text-left text-base">{{ item.name }}</td>
        <td class="text-left text-base">{{ item.text }}</td>
        <td class="text-center">
            <div class="flex flex-row items-center justify-center gap-1">
                <div class="tooltip" data-tip="Edit question">
                    <button @click="this.$emit('edit_item', String(question_type), {id: item.id, name: item.name, text: item.text, category: item.category ? item.category.id : null})" class="btn btn-square btn-ghost btn-sm">
                        <PencilIcon :size="18" class="pointer-events-none text-info" />
                    </button>
                </div>
                <div class="tooltip" data-tip="Delete question">
                    <button @click="this.$emit('delete_item', 'interview_questions', question_type, item.id)" class="btn btn-square btn-ghost btn-sm">
                        <DeleteIcon :size="18" class="pointer-events-none text-error" />
                    </button>
                </div>
            </div>
        </td>
    </tr>

    <!-- mobile card -->
    <div class="flex flex-col w-full items-center justify-center card shadow border rounded-lg p-4 text-xs" style="font-family: Questrial;" v-if="item && is_mobile">
        
        <div class="flex flex-row items-center justify-between gap-2 w-full">
            <!-- name -->
            <div class="flex flex-col items-start justify-center gap-1 w-full">
                <p class="text-gray-500 text-left">Title:</p>
                <p class="text-left whitespace-nowrap">{{ item.name }}</p>
            </div>

            <div class="divider divider-horizontal py-1 my-1"></div>

            <!-- buttons -->
            <div class="flex flex-row items-center justify-end w-full">
                <div class="flex flex-col items-center justify-center">
                    <p>Actions</p>
                    <div class="flex flex-row items-center justify-center gap-2 mt-2">
                        <div class="tooltip" data-tip="Edit question">
                            <button @click="this.$emit('edit_item', 'edit', 'interview_question_' + String(question_type), {id: item.id, name: item.name, text: item.text, category: item.category ? item.category.id : null})" class="btn btn-square btn-ghost btn-sm">
                                <PencilIcon :size="18" class="pointer-events-none text-info" />
                            </button>
                        </div>
                        <div class="tooltip" data-tip="Delete question">
                            <button @click="this.$emit('delete_item', 'interview_questions', question_type, item.id)" class="btn btn-square btn-ghost btn-sm">
                                <DeleteIcon :size="18" class="pointer-events-none text-error" />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="divider py-1 my-1"></div>

        <!-- text -->
        <div class="flex flex-col items-start justify-center gap-1">
            <p class="text-gray-500 text-left">Text:</p>
            <p class="text-left">{{ item.text }}</p>
        </div>
    </div>
</template>

<script>
// icons
import DeleteIcon from 'vue-material-design-icons/Delete.vue';
import PencilIcon from 'vue-material-design-icons/Pencil.vue';

export default {
    name: 'AdminInterviewQuestionsItem',
    emits: ['delete_item', 'edit_item'],
    props: ['index', 'item', 'is_mobile', 'question_type'],
    components: {
        // icons
        DeleteIcon,
        PencilIcon,
    },
}
</script>

