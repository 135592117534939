<template>
    <div dir="ltr" style="font-family: Questrial;">
        <div v-show="dialog_visible"
            x-transition:enter="transition duration-300 ease-out"
            x-transition:enter-start="translate-y-4 opacity-0 sm:translate-y-0 sm:scale-95"
            x-transition:enter-end="translate-y-0 opacity-100 sm:scale-100"
            x-transition:leave="transition duration-150 ease-in"
            x-transition:leave-start="translate-y-0 opacity-100 sm:scale-100"
            x-transition:leave-end="translate-y-4 opacity-0 sm:translate-y-0 sm:scale-95"
            class="fixed flex flex-row items-center justify-center z-10 w-full h-full top-0 bottom-0 left-0 right-0 bg-gray-600 bg-opacity-70 overflow-y-auto"
            aria-labelledby="modal-title" role="dialog" aria-modal="true">
            <div class="flex flex-row items-center justify-center min-h-screen w-full p-6">
                <div class="relative flex flex-col items-center max-h-[500px] md:max-h-[650px] h-full max-w-sm overflow-y-auto overflow-x-hidden justify-start transition-all transform bg-white rounded-lg shadow-xl w-full p-6">
                    <div class="w-full flex flex-col items-center justify-start gap-8">
                        <p id="dialog_title" class="block w-full mb-2 text-lg font-bold capitalize"></p>
                        <!-- content category name -->
                        <div class="relative w-full" v-if="dialog_data.content_type == 'content_category'">
                            <label for="content_category_name" class="block mb-2 text-lg font-semibold">Content category name</label>
                            <div class="flex flex-col items-center justify-center w-full gap-1">
                                <input v-model="dialog_data.content_category_name" type="text" name="content_category_name" id="content_category_name" dir="ltr" class="block w-full text-lg input input-bordered" />
                            </div>
                        </div>

                        <!-- artist category name -->
                        <div class="relative w-full" v-if="dialog_data.content_type == 'artist_category'">
                            <label for="artist_category_name" class="block mb-2 text-lg font-semibold">Artist category name</label>
                            <div class="flex flex-col items-center justify-center w-full gap-1">
                                <input v-model="dialog_data.artist_category_name" type="text" name="artist_category_name" id="artist_category_name" dir="ltr" class="block w-full text-lg input input-bordered" />
                            </div>
                        </div>

                        <!-- starter text -->
                        <div class="relative w-full" v-if="dialog_data.content_type == 'starter'">
                            <label for="starter_text" class="block mb-2 text-lg font-semibold">Starter text</label>
                            <div class="flex flex-col items-center justify-center w-full gap-1">
                                <input v-model="dialog_data.starter_text" type="text" name="starter_text" id="starter_text" dir="ltr" class="block w-full text-lg input input-bordered" />
                            </div>
                        </div>

                        <!-- invitation code - is_used -->
                        <div class="w-full flex flex-row items-center justify-start gap-4" v-if="dialog_data.content_type == 'invitation_code'">
                            <label for="invite_code_is_used" class="text-lg font-semibold">Used</label>
                            <input v-model="dialog_data.invite_code_is_used" name="invite_code_is_used" type="checkbox" class="toggle toggle-primary" />
                        </div>

                        <!-- invitation code - code -->
                        <div class="w-full" v-if="dialog_data.content_type == 'invitation_code'">
                            <label for="invite_code_code" class="block mb-2 text-lg font-semibold">Code</label>
                            <div class="flex flex-col items-center justify-center w-full gap-1">
                                <input v-model="dialog_data.invite_code" readonly type="text" name="invite_code_code" id="invite_code_code" dir="ltr" class="block w-full text-lg input input-bordered" />
                            </div>
                        </div>

                        <!-- invitation code - create date -->
                        <div class="w-full" v-if="dialog_data.content_type == 'invitation_code'">
                            <label for="invite_code_time" class="block mb-2 text-lg font-semibold">Create date</label>
                            <div class="flex flex-col items-center justify-center w-full gap-1">
                                <input v-model="dialog_data.invite_code_create_date" type="date" name="invite_code_time" ref="invite_code_time_ref" id="invite_code_time" dir="ltr" class="block w-full text-lg input input-bordered" />
                                <p class="text-xs text-left w-full"><span class="text-error">*</span> The expiration date of the code is calculated two weeks after the creation date.</p>
                            </div>
                        </div>

                        <!-- interview question 1 category -->
                        <div class="w-full" v-if="dialog_data.content_type == 'interview_question_1'">
                            <label for="interview_question_1_category" class="block mb-2 text-lg font-semibold">Category</label>
                            <select v-model="dialog_data.interview_question_1_category" class="select select-bordered w-full">
                                <option disabled selected>Choose a category</option>
                                <template v-for="(cat, cat_index) in this.content_categories" :key="cat_index">
                                    <option :value="cat.id">{{ cat.name }}</option>
                                </template>
                            </select>
                        </div>

                        <!-- interview question 1 name -->
                        <div class="relative w-full" v-if="dialog_data.content_type == 'interview_question_1'">
                            <label for="interview_question_1_name" class="block mb-2 text-lg font-semibold">Question name</label>
                            <div class="flex flex-col items-center justify-center w-full gap-1">
                                <input v-model="dialog_data.interview_question_1_name" type="text" name="interview_question_1_name" id="interview_question_1_name" class="block text-lg w-full input input-bordered" />
                            </div>
                        </div>

                        <!-- interview question 1 text -->
                        <div class="w-full" v-if="dialog_data.content_type == 'interview_question_1'">
                            <label for="interview_question_1_text" class="block mb-2 text-lg font-semibold">Question text</label>
                            <div class="flex flex-row items-center justify-center w-full gap-2">
                                <textarea v-model="dialog_data.interview_question_1_text" rows="6" cols="10" name="interview_question_1_text" id="interview_question_1_text" class="block w-full text-lg textarea textarea-bordered"></textarea>
                            </div>
                        </div>

                        <!-- interview question 2 category -->
                        <div class="w-full" v-if="dialog_data.content_type == 'interview_question_2'">
                            <label for="interview_question_2_category" class="block mb-2 text-lg font-semibold">Category</label>
                            <select v-model="dialog_data.interview_question_2_category" name="interview_question_2_category" class="select select-bordered w-full">
                                <option disabled selected>Choose a category</option>
                                <template v-for="(cat, cat_index) in this.content_categories" :key="cat_index">
                                    <option :value="cat.id">{{ cat.name }}</option>
                                </template>
                            </select>
                        </div>

                        <!-- interview question 2 name -->
                        <div class="relative w-full" v-if="dialog_data.content_type == 'interview_question_2'">
                            <label for="interview_question_2_name" class="block mb-2 text-lg font-semibold">Question name</label>
                            <div class="flex flex-col items-center justify-center w-full gap-1">
                                <input v-model="dialog_data.interview_question_2_name" type="text" name="interview_question_2_name" id="interview_question_2_name" class="block w-full text-lg input input-bordered" />
                            </div>
                        </div>

                        <!-- interview question 2 text -->
                        <div class="w-full" v-if="dialog_data.content_type == 'interview_question_2'">
                            <label for="interview_question_2_text" class="block mb-2 text-lg font-semibold">Question text</label>
                            <div class="flex flex-row items-center justify-center w-full gap-2">
                                <textarea v-model="dialog_data.interview_question_2_text" rows="6" cols="10" name="interview_question_2_text" id="interview_question_2_text" class="block w-full text-lg textarea textarea-bordered"></textarea>
                            </div>
                        </div>

                    </div>
                    <div class="flex gap-2 items-center justify-end w-full mt-5">
                        <button @click="close_dialog" class="btn flex flex-row items-center justify-center gap-2">
                            <CloseIcon :size="18" class="text-base-content" />
                            Close
                        </button>
                        <button @click="submit" class="btn flex flex-row items-center justify-center gap-2">
                            <ContentSaveOutlineIcon :size="18" class="text-blue-500" />
                            Submit
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
//icons
import CloseIcon from 'vue-material-design-icons/Close.vue';
import PencilIcon from 'vue-material-design-icons/Pencil.vue';
import CheckIcon from 'vue-material-design-icons/Check.vue';
import ContentSaveOutlineIcon from 'vue-material-design-icons/ContentSaveOutline.vue';

export default {
    name: 'AddEditDialog',
    emits: ['callback'],
    data() {
        return {
            dialog_visible: false,
            content_categories: [],
            dialog_data: {request_type: 'add', content_type: 'content_category', id: null, starter_text: '', content_category_name: '', artist_category_name: '', interview_question_1_category: -1, interview_question_1_name: '', interview_question_1_text: '', interview_question_2_category: -1, interview_question_2_name: '', interview_question_2_text: '', invite_code: '', invite_code_create_timestamp: 0, invite_code_is_used: false, invite_code_create_date: new Date()},
        }
    },
    components: {
        CloseIcon,
        PencilIcon,
        CheckIcon,
        ContentSaveOutlineIcon,
    },
    methods: {
        show_dialog(request_type, content_type, _data=null) {
            if(request_type && (request_type == 'add' || request_type == 'edit') && content_type && (content_type == 'starter' || content_type == 'content_category' || content_type == 'artist_category' || content_type == 'interview_question_1' || content_type == 'interview_question_2' || content_type == 'invitation_code'))
            {
                this.clear_dialog();
                this.dialog_data.request_type = request_type;
                this.dialog_data.content_type = content_type;
                if(request_type == 'edit' && _data)
                {
                    this.dialog_data.id = _data.id;
                    if(content_type == 'content_category')
                    {
                        this.dialog_data.content_category_name = _data.name;
                    }
                    else if(content_type == 'starter')
                    {
                        this.dialog_data.starter_text = _data.text;
                    }
                    else if(content_type == 'artist_category')
                    {
                        this.dialog_data.artist_category_name = _data.name;
                    }
                    else if(content_type == 'interview_question_1')
                    {
                        this.dialog_data.interview_question_1_name = _data.name;
                        this.dialog_data.interview_question_1_text = _data.text;
                        if(_data.category)
                            this.dialog_data.interview_question_1_category = _data.category;
                        this.content_categories = _data.content_categories;
                    }
                    else if(content_type == 'interview_question_2')
                    {
                        this.dialog_data.interview_question_2_name = _data.name;
                        this.dialog_data.interview_question_2_text = _data.text;
                        if(_data.category)
                            this.dialog_data.interview_question_2_category = _data.category;
                        this.content_categories = _data.content_categories;
                    }
                    else if(content_type == 'invitation_code')
                    {
                        this.dialog_data.invite_code = _data.code;
                        this.dialog_data.invite_code_create_timestamp = _data.create_time;
                        this.dialog_data.invite_code_is_used = _data.is_used;
                        this.dialog_data.invite_code_create_date = new Date(_data.create_time * 1000).toISOString().split('T')[0];
                    }
                }
                else if(request_type == 'add' && _data)
                {
                    if(content_type == 'interview_question_1' || content_type == 'interview_question_2')
                    {
                        this.content_categories = _data.content_categories;
                    }
                }
                document.getElementById('dialog_title').innerText = request_type + ' ' + content_type.replaceAll('_', ' ');
                this.dialog_visible = true;
            }
        },
        clear_dialog() {
            this.dialog_data = {request_type: 'add', content_type: 'content_category', id: null, starter_text: '', content_category_name: '', artist_category_name: '', interview_question_1_category: -1, interview_question_1_name: '', interview_question_1_text: '', interview_question_2_category: -1, interview_question_2_name: '', interview_question_2_text: '', invite_code: '', invite_code_create_timestamp: 0, invite_code_is_used: false, invite_code_create_date: new Date()};
        },
        async submit() {
            if(this.dialog_data.content_type == 'content_category' && !this.dialog_data.content_category_name)
            {
                this.$store.dispatch('addToast', {msg: 'Please write the name of the content category', type: 'error'});
                return false;
            }

            if(this.dialog_data.content_type == 'artist_category' && !this.dialog_data.artist_category_name)
            {
                this.$store.dispatch('addToast', {msg: 'Please write the name of the artist category', type: 'error'});
                return false;
            }

            if(this.dialog_data.content_type == 'starter' && !this.dialog_data.starter_text)
            {
                this.$store.dispatch('addToast', {msg: 'Please write the text of the starter', type: 'error'});
                return false;
            }

            if(this.dialog_data.content_type == 'interview_question_1')
            {
                if(!this.dialog_data.interview_question_1_category == -1)
                {
                    this.$store.dispatch('addToast', {msg: 'Please select a category for the question', type: 'error'});
                    return false;
                }
                if(!this.dialog_data.interview_question_1_name)
                {
                    this.$store.dispatch('addToast', {msg: 'Please write the name of the question', type: 'error'});
                    return false;
                }
                if(!this.dialog_data.interview_question_1_text)
                {
                    this.$store.dispatch('addToast', {msg: 'Please write the text of the question', type: 'error'});
                    return false;
                }
            }

            if(this.dialog_data.content_type == 'interview_question_2')
            {

                if(!this.dialog_data.interview_question_2_category == -1)
                {
                    this.$store.dispatch('addToast', {msg: 'Please select a category for the question', type: 'error'});
                    return false;
                }
                if(!this.dialog_data.interview_question_2_name)
                {
                    this.$store.dispatch('addToast', {msg: 'Please write the name of the question', type: 'error'});
                    return false;
                }
                if(!this.dialog_data.interview_question_2_text)
                {
                    this.$store.dispatch('addToast', {msg: 'Please write the text of the question', type: 'error'});
                    return false;
                }
            }

            if(this.dialog_data.content_type == 'invitation_code')
            {

                if(!this.dialog_data.invite_code_create_date)
                {
                    this.$store.dispatch('addToast', {msg: 'Please select the code creation date', type: 'error'});
                    return false;
                }
            }

            this.dialog_data.invite_code_create_timestamp = new Date(this.dialog_data.invite_code_create_date).getTime() / 1000;

            this.$store.state.is_loading = true;
            await axios.post('/api/v1/data/actions/', {form_data: this.dialog_data, token: this.$store.state.auth_token}, this.$store.state.axios_config).then(response => {
                    this.$store.dispatch('addToast', {msg: 'Information was added', type: 'success'});
                    this.$emit('callback', response.data);
                    this.close_dialog(true);
                }).catch((error) => {
                    if(error.response.status == 403)
                        this.$store.dispatch('addToast', {msg: 'You do not have access to this information', type: 'error'});
                    else if(error.response.status == 404)
                        this.$store.dispatch('addToast', {msg: 'No information found', type: 'error'});
                    else if(error.response.status == 503)
                        this.$store.dispatch('addToast', {msg: 'Duplicate information', type: 'error'});
                    else
                        this.$store.dispatch('addToast', {msg: 'An error has occurred on the server side, please contact support', type: 'error'});
            });
            setTimeout(() => {
                this.$store.state.is_loading = false;
            }, 500);
        },
        close_dialog(disable_loading=false) {
            if(disable_loading == true)
            {
                setTimeout(() => {
                    this.$store.state.is_loading = false;
                    this.dialog_visible = false;
                }, 500);
            }
            else
                this.dialog_visible = false;
        },
    },
}
</script>
