<template>
    <div style="font-family: Questrial;" dir="ltr">
        <div v-show="dialog_visible"
            x-transition:enter="transition duration-300 ease-out"
            x-transition:enter-start="translate-y-4 opacity-0 sm:translate-y-0 sm:scale-95"
            x-transition:enter-end="translate-y-0 opacity-100 sm:scale-100"
            x-transition:leave="transition duration-150 ease-in"
            x-transition:leave-start="translate-y-0 opacity-100 sm:scale-100"
            x-transition:leave-end="translate-y-4 opacity-0 sm:translate-y-0 sm:scale-95"
            class="fixed flex flex-row items-center justify-center z-10 w-full h-full top-0 bottom-0 left-0 right-0 bg-gray-600 bg-opacity-70 overflow-y-auto"
            aria-labelledby="modal-title" role="dialog" aria-modal="true">
            <div class="flex flex-row items-center justify-center min-h-screen w-full">
                <div class="relative flex flex-col items-center justify-center overflow-hidden transition-all transform bg-white rounded-lg shadow-xl max-w-sm w-full p-6">
                    <div>
                        <div class="flex items-center justify-center">
                            <DeleteIcon v-show="dialog_icon == 'delete'" :size="30" class="text-gray-700" />
                            <InformationIcon v-show="dialog_icon == 'info'" :size="30" class="text-gray-700" />
                            <AlertIcon v-show="dialog_icon == 'warning'" :size="30" class="text-gray-700" />
                        </div>
                        <div class="mt-2 text-center">
                            <h3 class="text-lg font-medium leading-6 text-gray-800 capitalize dark:text-white" id="modal-title">{{ dialog_title }}</h3>
                            <p class="mt-2 text-md text-gray-500 dark:text-gray-400">
                                {{ dialog_message }}
                            </p>
                        </div>
                    </div>
                    <div class="flex gap-2 items-center justify-end w-full mt-5">
                        <button @click="close_dialog" class="btn">
                            No
                        </button>
                        <button @click="submit" class="btn btn-error text-gray-100">
                            Yes
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
//icons
import DeleteIcon from 'vue-material-design-icons/Delete.vue';
import InformationIcon from 'vue-material-design-icons/Information.vue';
import AlertIcon from 'vue-material-design-icons/Alert.vue';

export default {
    name: 'ConfirmationDialog',
    emits: ['callback'],
    components: {
        DeleteIcon,
        InformationIcon,
        AlertIcon,
    },
    data() {
        return {
            dialog_visible: false,
            dialog_data: null,
            dialog_callback_type: null,
            dialog_message: '',
            dialog_url: '',
            dialog_icon: 'delete',
            dialog_title: '',
            dialog_success_message: '',
        }
    },
    methods: {
        show_dialog(_data, _callback_type='', _message='', _title='', _icon='delete') {
            if(_data && _callback_type)
            {
                this.dialog_data = _data;
                this.dialog_callback_type = _callback_type;
                this.dialog_message = _message;
                this.dialog_icon = _icon;
                this.dialog_title = _title;
                this.dialog_visible = true;
            }
        },
        submit() {
            this.$emit('callback', {type: this.dialog_callback_type, data:this.dialog_data});
            this.close_dialog();            
        },
        close_dialog() {
            this.dialog_visible = false;
        },
    },
}
</script>
