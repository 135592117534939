<template>
    <div style="font-family: Questrial;"dir="ltr" class="flex w-full shadow border rounded-lg flex-row items-center justify-center p-4">
        <p class="text-md text-center whitespace-nowrap text-gray-700" dir="ltr">No items found 😕</p>
    </div>
</template>

<script>
    export default {
        name: 'NoItemsFoundComponent'
    }
</script>
