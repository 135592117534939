<template>
    <Navbar :use_bg="true" logo_size="small" logo_mobile_size="small" />
    <div class="w-full p-10 sm:14" style="font-family: Questrial;">
        <div class="flex flex-col items-center justify-start w-full bg-base-100 overflow-x-hidden overflow-y-hidden">
            <p class="text-4xl text-gray-800 w-full text-left font-bold md:max-w-[70%]">Privacy & Terms of Service</p>
            <p class="text-lg text-gray-700 w-full text-justify md:text-left md:max-w-[70%] mt-4">
                Welcome to the History of Colors (“Sites”), applications and services provided by Iranian Artist Organization (collectively, the “Services”). These Terms of Service (“Terms”)  govern your access and use of the Services provided by Iranian Artist Organization, and any all of its subsidiaries, affiliates, brands and entities that it controls Each time you access and use the Services, you signify your acceptance of these terms.
            </p>

            <p class="text-2xl font-bold text-gray-700 w-full text-left md:max-w-[70%] mt-4">
                1. Your Privacy & Your Responsibilities User Submissions.
            </p>

            <p class="text-lg text-gray-700 w-full text-justify md:text-left md:max-w-[70%] mt-4">
                We do not claim ownership of any information, data, text, software, music, sound, photographs, graphics, video, messages, tags or other materials  submitted for display or distribution to others through the Services via comments and interviews (collectively, “User Submissions”). As between you and us, you own all rights to your User Submissions.
            </p>

            <p class="text-lg font-bold text-gray-700 w-full text-justify md:text-left md:max-w-[70%] mt-4">
                Content that is submitted to us for interview purposes will not be used for any purpose other than the interview and the promotion of the interview.  We will not use content submitted by you for any other purpose.  We may aggregate, share, or otherwise use that content or data for any commercial purpose, advertising, or otherwise.
            </p>

            <p class="text-lg text-gray-700 w-full text-justify md:text-left md:max-w-[70%] mt-4">
                However, you grant (and confirm and promise to us that you have the right to grant) to us an irrevocable, perpetual, non-exclusive, royalty-free and fully-paid, license throughout the universe to use the content for it’s said purpose (in the case of interviews for the interview and promotion of the interview). You hereby irrevocably waive (and agree to cause to be waived) any claims and assertions of moral rights or attributions with respect to your User Submissions. In consideration for us granting you access and use of the Services, you agree that we may place such advertisements throughout our Services. 
            </p>

            <p class="text-lg text-gray-700 w-full text-justify md:text-left md:max-w-[70%] mt-4">
                We are not required to backup, host, display, or distribute any User Submissions, and may remove at any time or refuse any User Submissions. You represent and warrant that your User Submissions and our authorized use of such submissions do not and will not infringe the rights of any third party (including, without limitation, intellectual property rights, rights of privacy or publicity, or any other legal or moral rights). We own all rights, title, and interests in any compilation, collective work or other derivative work created by us using or incorporating your content (but not your original content). The rights granted under this section will survive the termination of these Terms.
            </p>

            <p class="text-lg text-gray-700 w-full text-justify md:text-left md:max-w-[70%] mt-4">
                All content and materials provided on the Services are intended for general information, general discussion, education, and entertainment purposes only. Such content is not endorsed or verified by us. The content is provided “as is,” and your use or reliance on such materials are solely at your own risk.
            </p>

            <p class="text-xl font-bold text-gray-700 w-full text-left md:max-w-[70%] mt-4">
                Responsibilities:
            </p>

            <p class="text-lg text-gray-700 w-full text-justify md:text-left md:max-w-[70%] mt-4">
                You are responsible for any equipment needed for access to and use of the Services. You agree not to: (a) use the Services violate any local, state, national, or international law or regulation; (b) use the Services to send any material that is abusive, defamatory, vulgar, racist, discriminatory, pornographic, obscene, libelous, or otherwise objectionable; (c) use the Services to send any unsolicited or unauthorized advertising, promotional materials, junk mail, spam or any other form of solicitation; (d) use the Services send any material that contains adware, malware, spyware, software viruses, or anything else meant to interrupt, destroy, or limit the functionality of any computer software or hardware or telecommunications equipment or system; (e) use the Services stalk, harass, or harm another individual; (f) impersonate any person or entity, or otherwise misrepresent your affiliation with a person or entity; (g) use any data-mining technology or automatic or manual process to monitor, cache, frame, mask, extract data from, copy or distribute any data from the Services; (h) use any features of the Services for anything other than their intended purpose;
            </p>

            <p class="text-xl font-bold text-gray-700 w-full text-left md:max-w-[70%] mt-4">
                Google Analytics    
            </p>

            <p class="text-lg text-gray-700 w-full text-justify md:text-left md:max-w-[70%] mt-4">
                We may use Google Analytics to analyze the use of our website. Google Analytics gathers information about website use by means of cookies. The information gathered relating to our website is used to create reports about the use of our website. Google’s privacy policy is available at: <a class="font-bold underline underline-offset-4" href="https://www.google.com/policies/privacy/">https://www.google.com/policies/privacy/</a>
            </p>

            <p class="text-lg text-gray-700 w-full text-justify md:text-left md:max-w-[70%] mt-4">
                Most browsers allow you to refuse to accept cookies and to delete cookies. The methods for doing so vary from browser to browser, and from version to version. You can however obtain up-to-date information about blocking and deleting cookies via these links:
            </p>

            <p class="text-lg text-gray-700 w-full text-left md:max-w-[70%] mt-4">
                (a) <a class="font-bold underline underline-offset-4" href="https://support.google.com/chrome/answer/95647?hl=en">https://support.google.com/chrome/answer/95647?hl=en</a> (Chrome);
            </p>

            <p class="text-lg text-gray-700 w-full text-left md:max-w-[70%] mt-4">
                (b) <a class="font-bold underline underline-offset-4" href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences">https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences</a> (Firefox);
            </p>

            <p class="text-lg text-gray-700 w-full text-left md:max-w-[70%] mt-4">
                (c) <a class="font-bold underline underline-offset-4" href="http://www.opera.com/help/tutorials/security/cookies/">http://www.opera.com/help/tutorials/security/cookies/</a> (Opera);
            </p>

            <p class="text-lg text-gray-700 w-full text-left md:max-w-[70%] mt-4">
                (d) <a class="font-bold underline underline-offset-4" href="https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies">https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies</a> (Internet Explorer);
            </p>

            <p class="text-lg text-gray-700 w-full text-left md:max-w-[70%] mt-4">
                (e) <a class="font-bold underline underline-offset-4" href="https://support.apple.com/en-gb/guide/safari/sfri11471/mac">https://support.apple.com/en-gb/guide/safari/sfri11471/mac</a> (Safari); and
            </p>

            <p class="text-lg text-gray-700 w-full text-left md:max-w-[70%] mt-4">
                (f) <a class="font-bold underline underline-offset-4" href="https://privacy.microsoft.com/en-us/windows-10-microsoft-edge-and-privacy">https://privacy.microsoft.com/en-us/windows-10-microsoft-edge-and-privacy</a> (Edge).
            </p>

            <p class="text-lg text-gray-700 w-full text-justify md:text-left md:max-w-[70%] mt-4">
                Please note that blocking cookies may have a negative impact on the functions of many websites, including our Site. Some features of the Site may cease to be available to you.
            </p>

            <p class="text-xl font-bold text-gray-700 w-full text-left md:max-w-[70%] mt-4">
                Advertising
            </p>

            <p class="text-lg text-gray-700 w-full text-justify md:text-left md:max-w-[70%] mt-4">
                This Site is affiliated with Rethink Cnergy LLC (“RCLLC”) for the purposes of placing advertising on the Site, and RCLLC will collect and use certain data for advertising purposes.
            </p>

            <p class="text-2xl font-bold text-gray-700 w-full text-left md:max-w-[70%] mt-4">
                2. Copyright Infringement and Trademark Rights.
            </p>

            <p class="text-lg text-gray-700 w-full text-justify md:text-left md:max-w-[70%] mt-4">
                We respect the intellectual property rights of others and it is our policy to remove User Submissions that violate copyright law, suspending access to the Services (or any portion thereof) to any user who uses the Services in violation of copyright law, and/or terminating in appropriate circumstances the account of any user who uses the Services in violation of copyright law. Pursuant to Title 17 of the United States Code, Section 512, we have implemented procedures for receiving written notification of claimed copyright infringement and for processing such claims in accordance with such law. If you believe a user of the Services is infringing your copyright, please provide written notice to our agent listed below for notice of claims of copyright infringement.
            </p>

            <p class="text-lg text-gray-700 w-full text-left md:max-w-[70%] mt-4">
                Rethink Cnergy LLC
                <br>
                2102 Business Center Drive
                <br>
                Suite 253
                <br>
                Irvine, CA 92612
                <br>
                <a class="font-bold underline underline-offset-4" href="mailto:parham@rethinkcnergy.com">Email: parham@rethinkcnergy.com</a>
            </p>

            <p class="text-lg text-gray-700 w-full text-justify md:text-left md:max-w-[70%] mt-4">
                Your written notice must: (a) contain your physical or electronic signature; (b) identify the copyrighted work alleged to have been infringed; (c) identify the allegedly infringing material in a sufficiently precise manner to allow us to locate that material; (d) contain adequate information by which we can contact you (including postal address, telephone number, and e-mail address); (e) contain a statement that you have a good faith belief that use of the copyrighted material is not authorized by the copyright owner, the copyright owner’s agent, or the law; (f) contain a statement that the information in the written notice is accurate; and (g) contain a statement, under penalty of perjury, that you are authorized to act on behalf of the copyright owner. Please do not send notices or inquiries unrelated to alleged copyright infringement to our designated copyright agent.
            </p>

            <p class="text-lg text-gray-700 w-full text-justify md:text-left md:max-w-[70%] mt-4">
                If you believe that your trademark is being used somewhere on the Services in a way that constitutes trademark infringement, the owner or an agent of the owner may notify us at <a class="font-bold underline underline-offset-4" href="mailto:parham@rethinkcnergy.com">parham@rethinkcnergy.com</a>. We ask that any complaints provide the accurate identity of the owner, how we may contact you, and the specific nature of the complaint.
            </p>

            <p class="text-lg text-gray-700 w-full text-justify md:text-left md:max-w-[70%] mt-4">
                We may terminate your membership or suspend your access to all or part of the Services, without notice, if you violate these Terms or you engage in any conduct that we, in our sole and absolute discretion, believe is in violation of any applicable law or regulation or is otherwise harmful to the interests of us, any other user of the Services, or any third party. You agree that Iranian Artist Organization shall not be liable to you or any third party for removing your User Submissions or terminating your access to the Services
            </p>

            <p class="text-2xl font-bold text-gray-700 w-full text-left md:max-w-[70%] mt-4">
                3. Modifications to Terms.
            </p>

            <p class="text-lg text-gray-700 w-full text-justify md:text-left md:max-w-[70%] mt-4">
                We may, in our sole and absolute discretion, change these Terms from time to time. We may notify you of any changes by any reasonable means, including by posting a revised version of these Terms through the Services. If you object to any such changes, your sole recourse shall be to stop using the Services. Your continued use of the Services following such changes shall indicate your acknowledgement and acceptance of the revised Terms.
            </p>

            <p class="text-2xl font-bold text-gray-700 w-full text-left md:max-w-[70%] mt-4">
                4. Modifications to the Services.
            </p>

            <p class="text-lg text-gray-700 w-full text-justify md:text-left md:max-w-[70%] mt-4">
                We maintain and reserve the right to modify, suspend or discontinue all or any aspect of the Services with or without notice to you.
            </p>

            <p class="text-lg text-gray-700 w-full text-justify md:text-left md:max-w-[70%] mt-4">
                We may link to other Web sites or Internet resources for your convenience only, and such links should not be construed as our endorsement of such other Web sites or resource or its contents over which we have no control and which we do not monitor.
            </p>

            <p class="text-2xl font-bold text-gray-700 w-full text-left md:max-w-[70%] mt-4">
                5. Restrictions and Commercial Use.
            </p>

            <p class="text-lg text-gray-700 w-full text-justify md:text-left md:max-w-[70%] mt-4">
                Other than as provided for in these Terms you may not copy, make derivate works, resell, distribute, or make any commercial use of (other than to keep and share information for your own non-commercial purposes) any content, materials, or databases from our network or systems.
            </p>

            <p class="text-2xl font-bold text-gray-700 w-full text-left md:max-w-[70%] mt-4">
                6. Disclaimer of Warranties.
            </p>

            <p class="text-lg text-gray-700 w-full text-justify md:text-left md:max-w-[70%] mt-4">
                You expressly agree that use of the services is at your sole risk. We provide the Services on an “as is” and “as available” basis. We expressly disclaim all warranties of any kind, whether express or implied.
            </p>

            <p class="text-2xl font-bold text-gray-700 w-full text-left md:max-w-[70%] mt-4">
                7. Exclusions and Limitations.
            </p>

            <p class="text-lg text-gray-700 w-full text-justify md:text-left md:max-w-[70%] mt-4">
                Some jurisdictions do not allow the exclusion of certain warranties or the limitation or exclusion of liability for incidental or consequential damages. Accordingly, some of the above limitations and disclaimers may not apply to you. To the extent that we may not, as a matter of applicable law, disclaim any implied warranty or limit its liabilities, the scope and duration of such warranty and the extent of our liability shall be the minimum permitted under such applicable law.
            </p>

            <p class="text-lg text-gray-700 w-full text-justify md:text-left md:max-w-[70%] mt-4">
                You agree to indemnify, defend, and hold harmless us, our parents, subsidiaries, affiliates, officers, directors, employees, consultants, subcontractors and agents from and against any and all claims, liabilities, damages, losses, costs, expenses, fees (including reasonable attorney fees) that such parties may incur as a result of or arising from your (or anyone using your accounts) violation of these Terms.
            </p>

            <p class="text-2xl font-bold text-gray-700 w-full text-left md:max-w-[70%] mt-4">
                8. Copyrights; Restrictions on Use.
            </p>

            <p class="text-lg text-gray-700 w-full text-justify md:text-left md:max-w-[70%] mt-4">
                The content on the Services (the “Content”), including without limitation, video, text, photos, and graphics, is protected under United States and international copyright laws, is subject to other intellectual property and proprietary rights and laws, and is owned by us or our licensors. Other than with respect to your own User Submissions: (a) the Content may not be copied, modified, reproduced, republished, posted, transmitted, sold, offered for sale, or redistributed in any way without our prior written permission and that of our applicable licensors; and (b) you must abide by all copyright notices, information, or restrictions contained in or attached to any Content. We give you a personal, revocable, non-assignable, non-sublicensable and non-exclusive right to access and use the Services in the manner permitted by these Terms. You acknowledge that you have no right to have access to all or any part of the Services in source code form.
            </p>

            <p class="text-2xl font-bold text-gray-700 w-full text-left md:max-w-[70%] mt-4">
                9. Electronic Notices.
            </p>

            <p class="text-lg text-gray-700 w-full text-justify md:text-left md:max-w-[70%] mt-4">
                You agree to transact with us electronically. Your affirmative act of registering, using or logging into the Services constitutes your acceptance signature to these Terms. The delivery of any Notice is effective when sent or posted by us, regardless of whether you read the Notice or actually receive delivery. You can withdraw your consent to receive Notices electronically by discontinuing your use of the Service.
            </p>
        </div>
    </div>
    <Footer logo_size="normal" logo_mobile_size="small" class="p-10"></Footer>
</template>

<script>
    import Navbar from '@/components/navbar.vue';
    import Footer from '@/components/footer.vue';
    import axios from 'axios';
    
    export default {
        name: 'TermsOfService',
        components: {
            Navbar,
            Footer,
        },
        mounted() {
            document.title = 'History Of Colors - Privacy & Terms of Service';
            this.scrollToTop();
            this.refresh_data();
        },
        methods: {
            async refresh_data() {
                await axios.post('/api/v1/data/get/', {type: 'base_data'} , this.$store.state.axios_config).then(response => {
                    this.$store.state.base_data = response.data.base;
                })
                .catch(error => {
                    if((error.response && error.response.status && error.response.status == 404) || (error.request && error.request.status && error.request.status == 404))
                        this.$store.dispatch('addToast', {msg: 'No information found', type: 'error'});
                    else
                        this.$store.dispatch('addToast', {msg: 'An error has occurred on the server side, please contact support', type: 'error'});
                    console.log(error);
                });
            },
            // scroll page to top
            scrollToTop() {
                if(document.getElementById('wrapper'))
                    document.getElementById('wrapper').scrollTo({ top: 0, behavior: 'smooth' });
            },
        },
    }
</script>
