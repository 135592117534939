<template>
    <Navbar :use_bg="true" logo_size="small" logo_mobile_size="small" />
    <div class="w-full p-10" style="font-family: Questrial;">
        <div class="flex flex-col items-center justify-start w-full bg-base-100 overflow-x-hidden overflow-y-auto" v-if="item && !this.$store.state.is_loading">
            <div class="flex flex-col-reverse items-center justify-center gap-8 w-full md:max-w-[80%]" :class="{'md:flex-row': !post_image_is_horizonbtal}">
                <!-- post image -->
                <img id="post-image-0" class="w-full" :class="{'md:max-w-[40%]': !post_image_is_horizonbtal}" :src="this.$store.state.base_url + item.personal_image">
                <!-- title - date - category -->
                <div class="flex flex-col items-start justify-center gap-2 w-full">
                    <p class="text-3xl md:text-6xl w-full text-left font-semibold text-gray-800">{{ item.person.name }}</p>
                    <router-link target='_blank' :to="'/category/' + item.content_category.slug" v-if="item.content_category">
                        <p class="text-xl w-full text-left text-error">{{ item.content_category.name }}</p>
                    </router-link>
                    <router-link :to="'/artcategory/' + item.artist_category.slug" v-if="item.artist_category">
                        <p class="text-sm font-bold text-left uppercase text-blue-400">{{ item.artist_category.name }}</p>
                    </router-link>
                    <p v-else class="text-sm font-bold text-left uppercase text-blue-400">{{ item.custom_artist_category }}</p>
                    <p class="text-md w-full text-left text-gray-500">{{ get_date_string(item.date) }}</p>
                </div>
            </div>
            <div class="flex flex-col items-center justify-start md:max-w-[80%] w-full mt-8">
                <!-- starter -->
                <p v-show="item.starter && item.starter.length > 0" class="text-lg w-full text-justify md:text-left text-gray-700">{{ item.starter[0].text.replace('{name}', item.person.name) }}</p>

                <!-- about -->
                <p class="text-lg w-full text-gray-800 font-bold mt-8">{{ 'About ' + item.person.name }}</p>
                <p class="text-lg w-full text-justify md:text-left text-gray-800 mt-2">{{ item.about_answer }}</p>

                <!-- additional image 1 -->
                <img id="post-image-1" class="w-full mt-8" :class="{'md:max-w-[70%]': !post_images_1_is_horizonbtal}" :src="this.$store.state.base_url + item.additional_image_1" v-if="item.additional_image_1">

                <!-- question 1 -->
                <p class="text-lg w-full text-gray-800 font-bold mt-8">{{ item.question_1.text }}</p>
                <p class="text-lg w-full text-justify md:text-left text-gray-800 mt-2">{{ item.question_1_answer }}</p>

                <!-- additional image 2 -->
                <img id="post-image-2" class="w-full mt-8" :class="{'md:max-w-[70%]': !post_images_2_is_horizonbtal}" :src="this.$store.state.base_url + item.additional_image_2" v-if="item.additional_image_2">

                <!-- question 2 -->
                <p class="text-lg w-full text-gray-800 font-bold mt-8">{{ item.question_2.name }}</p>
                <p class="text-lg w-full text-justify md:text-left text-gray-800 mt-2">{{ item.question_2_answer }}</p>

                <!-- additional image 3 -->
                <img id="post-image-3" class="w-full mt-8" :class="{'md:max-w-[70%]': !post_images_3_is_horizonbtal}" :src="this.$store.state.base_url + item.additional_image_3" v-if="item.additional_image_3">

                <!-- question 3 -->
                <p class="text-lg w-full text-gray-800 font-bold mt-8">{{ item.question_3.name }}</p>
                <p class="text-lg w-full text-justify md:text-left text-gray-800 mt-2">{{ item.question_3_answer }}</p>

                <!-- additional image 4-1 -->
                <img id="post-image-4-1" class="w-full mt-8" :class="{'md:max-w-[70%]': !post_images_4_is_horizonbtal}" :src="this.$store.state.base_url + item.additional_image_4" v-if="item.additional_image_4 && item.additional_image_5">

                <!-- contact info -->
                <p class="text-lg w-full text-gray-800 font-bold mt-8">Contact Info:</p>
                <div class="flex flex-col items-start justify-start w-full mt-2">
                    <!-- website -->
                    <div class="flex flex-row items-center justify-start gap-2" v-if="item.website">
                        <p class="text-lg w-full text-gray-800 font-bold">Website:</p>
                        <a :href="item.website" target="_blank" class="text-lg w-full text-gray-800 underline underline-offset-2">{{ item.website }}</a>
                    </div>
                    <!-- instagram -->
                    <div class="flex flex-row items-center justify-start gap-2 mt-2" v-if="item.instagram">
                        <p class="text-lg w-full text-gray-800 font-bold">Instagram:</p>
                        <a :href="item.instagram" target="_blank" class="text-lg w-full text-gray-800 underline underline-offset-2">{{ item.instagram }}</a>
                    </div>
                    <!-- linkedin -->
                    <div class="flex flex-row items-center justify-start gap-2 mt-2" v-if="item.linkedin">
                        <p class="text-lg w-full text-gray-800 font-bold">Linkedin:</p>
                        <a :href="item.linkedin" target="_blank" class="text-lg w-full text-gray-800 underline underline-offset-2">{{ item.linkedin }}</a>
                    </div>
                    <!-- twitter -->
                    <div class="flex flex-row items-center justify-start gap-2 mt-2" v-if="item.twitter">
                        <p class="text-lg w-full text-gray-800 font-bold">Twitter:</p>
                        <a :href="item.twitter" target="_blank" class="text-lg w-full text-gray-800 underline underline-offset-2">{{ item.twitter }}</a>
                    </div>
                    <!-- facebook -->
                    <div class="flex flex-row items-center justify-start gap-2 mt-2" v-if="item.facebook">
                        <p class="text-lg w-full text-gray-800 font-bold">Facebook:</p>
                        <a :href="item.facebook" target="_blank" class="text-lg w-full text-gray-800 underline underline-offset-2">{{ item.facebook }}</a>
                    </div>
                    <!-- yelp -->
                    <div class="flex flex-row items-center justify-start gap-2 mt-2" v-if="item.yelp">
                        <p class="text-lg w-full text-gray-800 font-bold">Yelp:</p>
                        <a :href="item.yelp" target="_blank" class="text-lg w-full text-gray-800 underline underline-offset-2">{{ item.yelp }}</a>
                    </div>
                    <!-- youtube -->
                    <div class="flex flex-row items-center justify-start gap-2 mt-2" v-if="item.youtube">
                        <p class="text-lg w-full text-gray-800 font-bold">Instagram:</p>
                        <a :href="item.youtube" target="_blank" class="text-lg w-full text-gray-800 underline underline-offset-2">{{ item.youtube }}</a>
                    </div>
                    <!-- other_links -->
                    <div class="flex flex-col items-center justify-start gap-2 mt-2" v-if="item.other_links">
                        <p class="text-lg w-full text-gray-800 font-bold">Other links:</p>
                        <p class="text-lg w-full text-gray-800">{{ item.other_links }}</p>
                    </div>
                </div>

                <!-- additional image 4-2 -->
                <img id="post-image-4-2" class="w-full mt-8" :class="{'md:max-w-[70%]': !post_images_4_is_horizonbtal}" :src="this.$store.state.base_url + item.additional_image_4" v-if="item.additional_image_4 && !item.additional_image_5">

                <!-- additional image 5 -->
                <img id="post-image-5" class="w-full mt-8" :class="{'md:max-w-[70%]': !post_images_5_is_horizonbtal}" :src="this.$store.state.base_url + item.additional_image_5" v-if="item.additional_image_5">
            </div>
        </div>
        <!-- skeleton -->
        <div v-else class="flex flex-col items-center justify-center gap-4 w-full p-6">
            <div class="flex flex-col gap-4 w-56">
                <div class="skeleton h-32 w-full"></div>
                <div class="skeleton h-4 w-28"></div>
                <div class="skeleton h-4 w-full"></div>
                <div class="skeleton h-4 w-full"></div>
            </div>
        </div>
    </div>
    <Footer logo_size="normal" logo_mobile_size="small" class="p-10"></Footer>
</template>

<script>
import axios from 'axios';
import Navbar from '@/components/navbar.vue';
import Footer from '@/components/footer.vue';

export default {
    name: 'PostPage',
    components: {
        Navbar,
        Footer,
    },
    data() {
        return {
            item: null,
            post_image_is_horizonbtal: false,
            post_images_1_is_horizonbtal: false,
            post_images_2_is_horizonbtal: false,
            post_images_3_is_horizonbtal: false,
            post_images_4_is_horizonbtal: false,
            post_images_5_is_horizonbtal: false,
        }
    },
    mounted() {
        this.awake();
    },
    methods: {
        // awake and refresh data
        async awake() {
            if(this.$route.params.slug)
            {
                this.scrollToTop();
                // this.$store.state.is_loading = true;
                await axios.post('/api/v1/data/get/', {type: 'interview_data', slug: this.$route.params.slug, 'set_view': true} , this.$store.state.axios_config)
                    .then(response => {
                        this.item = response.data.post;
                        this.$store.state.base_data = response.data.base;
                    })
                    .catch(error => {
                        console.log(error)
                        if((error.response && error.response.status && error.response.status == 403) || (error.request && error.request.status && error.request.status == 403))
                            this.$store.dispatch('addToast', {msg: 'You do not have access to this information', type: 'error'});
                        else if((error.response && error.response.status && error.response.status == 404) || (error.request && error.request.status && error.request.status == 404))
                            this.$store.dispatch('addToast', {msg: 'No information found', type: 'error'});
                        else
                            this.$store.dispatch('addToast', {msg: 'An error has occurred on the server side, please contact support', type: 'error'});
                    });
                setTimeout(() => {
                    // this.$store.state.is_loading = false;
                    this.check_post_images_aspect_ratio();
                }, 500);
            }
            if(!this.item)
                this.$router.push({ path: '/404' });
            document.title = 'History Of Colors - ' + this.item.title;
        },
        // get date locale string
        get_date_string(timestamp) {
            var options = { year: 'numeric', month: 'long', day: 'numeric' };
            return new Date(timestamp * 1000).toLocaleDateString("en-US", options);
        },
        camelize(str) {
            return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function(match, index) {
                if (+match === 0) return ""; // or if (/\s+/.test(match)) for white spaces
                return index === 0 ? match.toLowerCase() : match.toUpperCase();
            });
        },
        check_post_images_aspect_ratio() {
            let item0 = document.getElementById('post-image-0');
            if(item0)
                this.post_image_is_horizonbtal = item0.offsetWidth > item0.offsetHeight ? true : false;

            let item1 = document.getElementById('post-image-1');
            if(item1)
                this.post_images_1_is_horizonbtal = item1.offsetWidth > item1.offsetHeight ? true : false;

            let item2 = document.getElementById('post-image-2');
            if(item2)
                this.post_images_2_is_horizonbtal = item2.offsetWidth > item2.offsetHeight ? true : false;

            let item3 = document.getElementById('post-image-3');
            if(item3)
                this.post_images_3_is_horizonbtal = item3.offsetWidth > item3.offsetHeight ? true : false;

            let item41 = document.getElementById('post-image-4-1');
            if(item41)
                this.post_images_4_is_horizonbtal = item41.offsetWidth > item41.offsetHeight ? true : false;

            let item42 = document.getElementById('post-image-4-1');
            if(item42)
                this.post_images_4_is_horizonbtal = item42.offsetWidth > item42.offsetHeight ? true : false;

            let item5 = document.getElementById('post-image-5');
            if(item5)
                this.post_images_5_is_horizonbtal = item5.offsetWidth > item5.offsetHeight ? true : false;
        },
        // scroll page to top
        scrollToTop() {
            if(document.getElementById('wrapper'))
                document.getElementById('wrapper').scrollTo({ top: 0, behavior: 'smooth' });
        },
    },
}
</script>
