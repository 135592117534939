<template>
    <div class="fixed top-0 left-0 right-0 bottom-0 w-full h-full flex flex-row items-center justify-center z-[99] bg-gray-800/80" v-show="is_visible">
        <img class="w-auto h-full object-contain pointer-events-none" :src="item_url" v-if="item_url">
        <button @click="close_component" class="btn btn-ghost btn-square cursor-pointer pointer-events-auto fixed top-0 right-0 m-6">
            <CloseIcon :size="32" class="pointer-events-none text-white" />
        </button>
    </div>
</template>

<script>
import CloseIcon from 'vue-material-design-icons/Close.vue';

export default {
    name: 'FullScreenImage',
    data() {
        return {
            is_visible: false,
            item_url: '',
        }
    },
    components: {
        CloseIcon,
    },
    methods: {
        show_component(_url) {
            if(_url)
            {
                this.$store.state.is_loading = true;
                this.item_url = _url;
                setTimeout(() => {
                    this.$store.state.is_loading = false;
                    this.is_visible = true;
                }, 500);
            }
        },
        close_component() {
            this.is_visible = false;
        },
    },
}
</script>
