<template>
    <div style="font-family: Questrial;" class="grid grid-cols-1 sm:grid-cols-2 w-full overflow-hidden gap-8">
        <!-- Latest -->
        <div class="flex flex-col items-start justify-start">
            <p class="text-black font-bold text-xl">Latest</p>
            <div class="flex flex-col sm:flex-row items-center justify-start gap-6 mt-10">
                <div class="w-full relative h-60 sm:max-w-[45%] group overflow-hidden">
                    <img class="object-cover w-full h-full transition-transform duration-500 ease-out group-hover:scale-110 cursor-pointer" src="@/assets/img/posts/1-2-768x1024.jpeg" alt="" />
                </div>
                <div class="flex flex-col items-center justify-start gap-2 w-full">
                    <p class="text-sm font-bold w-full uppercase text-red-500 text-left">PRODUCTIVITY</p>
                    <p class="text-xl font-bold w-full text-black text-left">Working hard in 2024: Keeping Work Ethic Alive</p>
                    <p class="text-base text-gray-700 w-full font-semibold text-left">While the media might often make it seem like hard work is dead and that</p>
                </div>
            </div>
            <div class="divider"></div>
            <div class="flex flex-col sm:flex-row items-center justify-start gap-6">
                <div class="w-full relative h-60 sm:max-w-[45%] group overflow-hidden">
                    <img class="object-cover w-full h-full transition-transform duration-500 ease-out group-hover:scale-110 cursor-pointer" src="@/assets/img/posts/1-684x1024.jpg" alt="" />
                </div>
                <div class="flex flex-col items-center justify-start gap-2 w-full">
                    <p class="text-sm font-bold w-full uppercase text-red-500 text-left">RESILIENCE</p>
                    <p class="text-xl font-bold w-full text-black text-left">The Power of Persistence: Overcoming Haters and Doubters</p>
                    <p class="text-base text-gray-700 w-full font-semibold text-left">Having hates is an inevitable part of any bold journey – everyone who has made</p>
                </div>
            </div>
            <div class="divider"></div>
            <div class="flex flex-col sm:flex-row items-center justify-start gap-6">
                <div class="w-full relative h-60 sm:max-w-[45%] group overflow-hidden">
                    <img class="object-cover w-full h-full transition-transform duration-500 ease-out group-hover:scale-110 cursor-pointer" src="@/assets/img/posts/1-3-768x1024.jpeg" alt="" />
                </div>
                <div class="flex flex-col items-center justify-start gap-2 w-full">
                    <p class="text-sm font-bold w-full uppercase text-red-500 text-left">LEADERSHIP DEVELOPMENT</p>
                    <p class="text-xl font-bold w-full text-black text-left">How did you develop your decision-making skills?</p>
                    <p class="text-base text-gray-700 w-full font-semibold text-left">Decision making can be stressful and anxiety inducing, but the ability to make decisions quickly</p>
                </div>
            </div>
        </div>
        <!-- Popular -->
        <div class="flex flex-col items-start justify-start">
            <p class="text-black font-bold text-xl">Popular</p>
            <div class="flex flex-col sm:flex-row items-center justify-start gap-6 mt-10">
                <div class="w-full relative h-60 sm:max-w-[45%] group overflow-hidden">
                    <img class="object-cover w-full h-full transition-transform duration-500 ease-out group-hover:scale-110 cursor-pointer" src="@/assets/img/posts/2-3-1-e1707199758214-768x576.jpg" alt="" />
                </div>
                <div class="flex flex-col items-center justify-start gap-2 w-full">
                    <p class="text-sm font-bold w-full uppercase text-red-500 text-left">CONFIDENCE</p>
                    <p class="text-xl font-bold w-full text-black text-left">Champion Mindset: Building Confidence & Self-Esteem</p>
                    <p class="text-base text-gray-700 w-full font-semibold text-left">Every day, our team is focused on how to help our audience and community reach</p>
                </div>
            </div>
            <div class="divider"></div>
            <div class="flex flex-col sm:flex-row items-center justify-start gap-6">
                <div class="w-full relative h-60 sm:max-w-[45%] group overflow-hidden">
                    <img class="object-cover w-full h-full transition-transform duration-500 ease-out group-hover:scale-110 cursor-pointer" src="@/assets/img/posts/1-1-224x300.jpeg" alt="" />
                </div>
                <div class="flex flex-col items-center justify-start gap-2 w-full">
                    <p class="text-sm font-bold w-full uppercase text-red-500 text-left">LEADERSHIP DEVELOPMENT</p>
                    <p class="text-xl font-bold w-full text-black text-left">Increasing Your Capacity for Risk-Taking</p>
                    <p class="text-base text-gray-700 w-full font-semibold text-left">The capacity to take risk is one of the biggest enablers of reaching your full</p>
                </div>
            </div>
            <div class="divider"></div>
            <div class="flex flex-col sm:flex-row items-center justify-start gap-6">
                <div class="w-full relative h-60 sm:max-w-[45%] group overflow-hidden">
                    <img class="object-cover w-full h-full transition-transform duration-500 ease-out group-hover:scale-110 cursor-pointer" src="@/assets/img/posts/1705450616338-200x300.jpg" alt="" />
                </div>
                <div class="flex flex-col items-center justify-start gap-2 w-full">
                    <p class="text-sm font-bold w-full uppercase text-red-500 text-left">PRODUCTIVITY</p>
                    <p class="text-xl font-bold w-full text-black text-left">Keeping Your Creativity Alive</p>
                    <p class="text-base text-gray-700 w-full font-semibold text-left">One of the most challenging aspects of creative work is keeping your creativity alive. If</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'LandingPageLatestPopular',
    }
</script>
