<template>
    <!-- pc table row -->
    <tr style="font-family: Questrial;" v-if="item && !is_mobile">
        <td class="text-center text-md" :class="{'border-l-2 border-success bg-success/10': !item.is_checked}">{{ index }}</td>
        <td class="text-center text-base">{{ item.name }}</td>
        <td class="text-center text-base">
            <a :href="'mailto:' + item.email">{{ item.email }}</a>
        </td>
        <td class="hidden md:table-cell text-center text-base">
            <p v-if="!item.website">-</p>
            <a :href="get_true_url(item.website)" target="_blank" v-if="item.website">{{ item.website }}</a>
        </td>
        <td class="hidden md:table-cell text-center text-base">
            <p v-if="!item.social_media">-</p>
            <a :href="get_true_url(item.social_media)" target="_blank" v-if="item.social_media">{{ item.social_media }}</a>
        </td>
        <td class="hidden md:table-cell text-center text-base">{{ item.your_name ? item.your_name : '-' }}</td>
        <td class="text-center text-base">
            <div class="flex flex-col md:flex-row items-center justify-center gap-1">
                <div class="flex flex-row items-center justify-center gap-1">
                    <div class="tooltip" data-tip="Send the first email">
                        <button @click="send_email('first_email')" class="btn btn-square btn-ghost btn-sm flex flex-row items-center justify-center relative">
                            <EmailIcon :size="18" class="pointer-events-none text-gray-500" :class="{'text-success': item.email_1_is_send}" />
                            <span class="absolute -top-1 right-0 text-xs font-bold text-gray-500" :class="{'text-success': item.email_1_is_send}">1</span>
                        </button>
                    </div>
                    <div class="tooltip" data-tip="Send the second email">
                        <button @click="send_email('second_email')" class="btn btn-square btn-ghost btn-sm flex flex-row items-center justify-center relative">
                            <EmailIcon :size="18" class="pointer-events-none text-gray-500" :class="{'text-primary': item.email_2_is_send}" />
                            <span class="absolute -top-1 right-0 text-xs font-bold text-gray-500" :class="{'text-primary': item.email_2_is_send}">2</span>
                        </button>
                    </div>
                </div>
                <div class="flex flex-row items-center justify-center gap-1">
                    <div class="tooltip" data-tip="Change information check status">
                        <button @click="change_is_checked" class="btn btn-square btn-ghost btn-sm">
                            <EyeCheckOutlineIcon v-if="item.is_checked" :size="18" class="pointer-events-none text-success" />
                            <EyeOutlineIcon v-else :size="18" class="pointer-events-none" />
                        </button>
                    </div>
                </div>
                <div class="tooltip" data-tip="Delete information">
                    <button @click="this.$emit('delete_item', 'person', this.item.id)" class="btn btn-square btn-ghost btn-sm">
                        <DeleteIcon :size="18" class="pointer-events-none text-error" />
                    </button>
                </div>
            </div>
        </td>
    </tr>
    <!-- mobile card -->
    <div class="flex flex-col w-full items-center justify-center card shadow border rounded-lg p-4 text-xs" style="font-family: Questrial;" v-if="item && is_mobile">
        <div class="flex flex-row w-full items-center justify-center gap-2">
            <!-- name -->
            <div class="flex flex-col items-center w-full justify-center gap-1">
                <p class="text-gray-500 w-full text-left">Name:</p>
                <p class="w-full text-left">{{ item.name }}</p>
            </div>
            <!-- divider -->
            <div class="divider divider-horizontal"></div>
            <!-- email -->
            <div class="flex flex-col items-center w-full justify-center gap-1">
                <p class="text-gray-500 w-full text-left">Email</p>
                <p class="w-full text-left">{{ item.email }}</p>
            </div>
        </div>
        <div class="divider py-1 my-1"></div>
        <p>Actions</p>
        <div class="flex flex-row items-center justify-center gap-2 mt-2">
            <div class="tooltip" data-tip="Send the first email">
                <button @click="send_email('first_email')" class="btn btn-square btn-ghost btn-sm flex flex-row items-center justify-center relative">
                    <EmailIcon :size="18" class="pointer-events-none text-gray-500" :class="{'text-success': item.email_1_is_send}" />
                    <span class="absolute -top-1 right-0 text-xs font-bold text-gray-500" :class="{'text-success': item.email_1_is_send}">1</span>
                </button>
            </div>
            <div class="tooltip" data-tip="Send the second email">
                <button @click="send_email('second_email')" class="btn btn-square btn-ghost btn-sm flex flex-row items-center justify-center relative">
                    <EmailIcon :size="18" class="pointer-events-none text-gray-500" :class="{'text-primary': item.email_2_is_send}" />
                    <span class="absolute -top-1 right-0 text-xs font-bold text-gray-500" :class="{'text-primary': item.email_2_is_send}">2</span>
                </button>
            </div>
            <div class="tooltip" data-tip="Change information check status">
                <button @click="change_is_checked" class="btn btn-square btn-ghost btn-sm">
                    <EyeCheckOutlineIcon v-if="item.is_checked" :size="18" class="pointer-events-none text-success" />
                    <EyeOutlineIcon v-else :size="18" class="pointer-events-none" />
                </button>
            </div>
            <div class="tooltip" data-tip="Delete information">
                <button @click="this.$emit('delete_item', 'person', this.item.id)" class="btn btn-square btn-ghost btn-sm">
                    <DeleteIcon :size="18" class="pointer-events-none text-error" />
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
// icons
import DeleteIcon from 'vue-material-design-icons/Delete.vue';
import EyeOutlineIcon from 'vue-material-design-icons/EyeOutline.vue';
import EyeCheckOutlineIcon from 'vue-material-design-icons/EyeCheckOutline.vue';
import EmailBoxIcon from 'vue-material-design-icons/EmailBox.vue';
import EmailIcon from 'vue-material-design-icons/Email.vue';

export default {
    name: 'AdminPersonItem',
    emits: ['delete_item', 'send_email_callback'],
    props: ['index', 'item', 'is_mobile'],
    components: {
        // icons
        DeleteIcon,
        EyeOutlineIcon,
        EyeCheckOutlineIcon,
        EmailBoxIcon,
        EmailIcon,
    },
    methods: {
        // change is_checked state
        async change_is_checked() {
            this.$store.state.is_loading = true;
            await axios.post('/api/v1/data/actions/', {request_type: 'change_checked', data_type: 'person', id: this.item.id, token: this.$store.state.auth_token} , this.$store.state.axios_config)
                .then(response => {
                    this.item.is_checked = !this.item.is_checked;
                    this.$store.dispatch('addToast', {msg: 'Changes were recorded', type: 'success'});
                })
                .catch(error => {
                    if(error.response.state == 403)
                        this.$store.dispatch('addToast', {msg: 'You do not have access to this information', type: 'error'});
                    else if(error.response.state == 404)
                        this.$store.dispatch('addToast', {msg: 'No information found', type: 'error'});
                    else
                        this.$store.dispatch('addToast', {msg: 'An error has occurred on the server side, please contact support', type: 'error'});
                    console.log(error);
                });
            setTimeout(() => {
                this.$store.state.is_loading = false;
            }, 500);
        },
        async send_email(_type) {
            this.$store.state.is_loading = true;
            await axios.post('/api/v1/mail/send/', {request_type: _type, person_id: this.item.id, token: this.$store.state.auth_token} , this.$store.state.axios_config)
                .then(response => {
                    this.$store.dispatch('addToast', {msg: 'Email Sent', type: 'success'});
                    this.$emit('send_email_callback', response.data);
                })
                .catch(error => {
                    if(error.response.state == 403)
                        this.$store.dispatch('addToast', {msg: 'You do not have access to this information', type: 'error'});
                    else if(error.response.state == 404)
                        this.$store.dispatch('addToast', {msg: 'No information found', type: 'error'});
                    else
                        this.$store.dispatch('addToast', {msg: 'An error has occurred on the server side, please contact support', type: 'error'});
                    if(this.$store.state.is_debug)
                        console.log(error);
                });
            setTimeout(() => {
                this.$store.state.is_loading = false;
            }, 500);
        },
        // change email send state
        async change_email_send_status(email_type) {
            this.$store.state.is_loading = true;
            await axios.post('/api/v1/data/actions/', {request_type: 'change_email_send_status', email_type: email_type, person_id: this.item.id, token: this.$store.state.auth_token} , this.$store.state.axios_config)
                .then(response => {
                    if(email_type == 1)
                        this.item.email_1_is_send = !this.item.email_1_is_send;
                    else if(email_type == 2)
                            this.item.email_2_is_send = !this.item.email_2_is_send;
                    this.$store.dispatch('addToast', {msg: 'Changes were recorded', type: 'success'});
                })
                .catch(error => {
                    if(error.response.state == 403)
                        this.$store.dispatch('addToast', {msg: 'You do not have access to this information', type: 'error'});
                    else if(error.response.state == 404)
                        this.$store.dispatch('addToast', {msg: 'No information found', type: 'error'});
                    else
                        this.$store.dispatch('addToast', {msg: 'An error has occurred on the server side, please contact support', type: 'error'});
                    console.log(error);
                });
            setTimeout(() => {
                this.$store.state.is_loading = false;
            }, 500);
        },
        get_true_url(value) {
            if(value)
                return value.startsWith("http://") || value.startsWith("https://") ? value : 'http://' + value;
            return '';
        }
    },
}
</script>
