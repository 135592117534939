<template>
  <div id="wrapper" class="main-view overflow-x-hidden overflow-y-auto" :data-theme="this.$store.state.app_theme" :class="{'dark': this.$store.state.use_dark}">
        <router-view />
        <LoadingComponent />
        <ToastComponent />
  </div>
</template>

<script>
import axios from 'axios';
import LoadingComponent from '@/components/loading-component.vue';
import ToastComponent from '@/components/toast-component.vue';

export default {
  name: 'app',
  mounted() {
      this.awake();
  },
  components: {
      LoadingComponent,
      ToastComponent,
  },
  methods: {
      awake() {
          document.title = 'History Of Colors';

          this.$store.state.base_url = axios.defaults.baseURL;

          let user_token = localStorage.getItem('user_token');
          let user_login_time = parseInt(String(localStorage.getItem('user_login_time')));
          let user_expire = parseInt(String(localStorage.getItem('user_expire')));
          if (user_token && user_login_time && user_expire && user_expire > 0 && Math.floor(Date.now() / 1000) < user_login_time + user_expire)
          {
              this.$store.state.is_authenticated = true;
              this.$store.state.auth_token = user_token;
          }
          else
          {
              localStorage.removeItem('user_token');
              localStorage.removeItem('user_login_time');
              localStorage.removeItem('user_expire');
              this.$store.state.is_authenticated = false;
          }

          let app_theme = localStorage.getItem('app_theme');
          let use_dark = localStorage.getItem('use_dark');
          if(app_theme && (app_theme == 'light' || app_theme == 'dark' || app_theme == 'cupcake' || app_theme == 'dracula' || app_theme == 'dim' || app_theme == 'night')
             && use_dark && (use_dark == 'true' || use_dark == 'false'))
          {
            this.$store.state.app_theme = app_theme;
            this.$store.state.use_dark = use_dark == 'true' ? true : false;
          }
      }
  },
}
</script>
