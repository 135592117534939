<template>

    <!-- navbar -->
    <nav id="navbar" class="overflow-hidden w-full flex max-h-auto flex-col items-center justify-start z-50 transition-all ease-linear" :class="{'dark': this.$store.state.use_dark, 'p-4 md:p-8': !use_menu_padding, 'p-6 md:p-12': use_menu_padding, 'bg-base-100': is_busy || use_bg || (!use_bg && menu_is_open), 'bg-transparent': !is_busy && !use_bg && !menu_is_open, 'border-b-2': use_bg, 'text-base-100': !use_bg && !menu_is_open && !is_busy, 'absolute top-0': !use_bg || menu_is_open || is_busy,}" style="font-family: Questrial;" :data-theme="this.$store.state.app_theme">
        <!-- top section -->
        <div class="flex flex-row items-start justify-between w-full gap-2">
            <div class="w-full flex flex-row items-center justify-start">
                <router-link to="/" class="cursor-pointer">
                    <Logo :class="{'md:h-auto': logo_size == 'free', 'md:h-24': logo_size == 'normal', 'md:h-20': logo_size == 'small', 'md:h-12': logo_size == 'tiny', 'h-auto': logo_mobile_size == 'free', 'h-24': logo_mobile_size == 'normal', 'h-20': logo_mobile_size == 'small', 'h-12': logo_mobile_size == 'tiny'}" :use_black="use_bg || menu_is_open || is_busy" />
                    <!-- <Logo :size="logo_size" :mobile_size="logo_mobile_size" :use_black="use_bg || menu_is_open || is_busy" /> -->
                </router-link>
            </div>
            <div class="flex flex-row items-center justify-center gap-1">
                <button class="btn btn-square flex flex-row items-center justify-center relative" :class="{'btn-ghost': !menu_is_open || (menu_is_open && menu_type != 'search')}" @click="menu_toggle('search')">
                    <magnify-icon :size="28" :class="{'text-base-content': menu_is_open, 'text-white': !use_bg && !menu_is_open}" />
                </button>
                <button class="btn btn-square flex flex-row items-center justify-center relative" :class="{'btn-ghost': !menu_is_open || (menu_is_open && menu_type != 'menu')}" @click="menu_toggle('menu')">
                    <menu-icon :size="28" :class="{'text-base-content': menu_is_open, 'text-white': !use_bg && !menu_is_open}" />
                </button>
            </div>
        </div>
        <!-- bottom section -->
        <div class="w-full bg-base-100 transition-all duration-500 ease-in-out overflow-y-auto sm:overflow-y-hidden h-fit" :class="{'max-h-0': !menu_is_open, 'max-h-[650px] sm:max-h-[600px]': menu_is_open}">
            <div class="divider"></div>
            <div class="flex flex-col md:flex-row w-full transition-all gap-x-16" v-show="menu_type == 'menu'">
                <div class="flex flex-col items-start justify-start w-fit gap-1">
                    <h5 class="mb-3 text-lg text-base-content font-bold">SITEMAP</h5>
                    <div class="flex flex-col md:flex-row items-start justify-start md:gap-y-6 gap-x-8">
                        <div class="flex flex-col items-start justify-start w-full gap-1">
                            <router-link to="/about-history-of-art/" class="text-base-content opacity-70 whitespace-nowrap">About History of Colors</router-link>
                            <router-link to="/recommend-someone/" class="text-base-content opacity-70 whitespace-nowrap">Recommend someone</router-link>
                            <router-link to="/terms-of-service/" class="text-base-content opacity-70 whitespace-nowrap">Terms of Service</router-link>
                        </div>
                    </div>
                </div>
                <div class="divider sm:hidden"></div>
                <div class="flex flex-col items-start justify-start gap-2">
                    <router-link to="/category/">
                        <h5 class="mb-3 text-lg text-base-content font-bold hover:underline underline-offset-4">CATEGORIES</h5>
                    </router-link>
                    <div class="flex flex-col items-start justify-start gap-2 w-fit">
                        <template v-for="(item, index) in this.$store.state.base_data.content_categories" v-key="index" v-if="this.$store.state.base_data && this.$store.state.base_data.content_categories && this.$store.state.base_data.content_categories.length > 0">
                            <router-link :to="'/category/' + item.slug" class="text-base-content opacity-70 hover:underline underline-offset-4">{{ item.name }}</router-link>
                        </template>
                    </div>
                </div>
                <div class="divider sm:hidden"></div>
                <div class="flex flex-col items-start justify-start w-full gap-2">
                    <router-link to="/artcategory/">
                        <h5 class="mb-3 text-lg text-base-content font-bold hover:underline underline-offset-4">ARTS</h5>
                    </router-link>
                    <div class="flex flex-row flex-wrap items-start justify-start gap-y-6 gap-x-8 md:gap-x-16 w-full">
                        <template v-for="(chunk, chunk_index) in get_artist_categories_chunks" v-key="chunk_index">
                            <div class="flex flex-col items-start justify-start w-fit gap-2">
                                <template v-for="(item, index) in chunk" :key="index">
                                    <router-link :to="'/artcategory/' + item.slug" class="text-base-content opacity-70 hover:underline underline-offset-4">{{ item.name }}</router-link>
                                </template>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
            <!-- search section -->
            <div class="relative w-full flex flex-row items-center justify-center gap-3" v-show="menu_type == 'search'">
                <input @keyup.enter="search_submit" v-model="search_text" dir="ltr" type="text" name="search" id="search" placeholder="Search History Of Colors" class="block pl-10 w-full input input-bordered border-gray-300 text-lg" />
                <MagnifyIcon :size="24" class="absolute left-3 top-1/2 -mt-[12px] text-gray-600 dark:text-gray-400" />
                <button @click="search_submit" class="btn pointer-events-auto text-lg">
                    Search
                </button>
            </div>
        </div>
    </nav>

    <!-- blur bg -->
    <Transition>
        <div v-show="menu_is_open || is_busy" @click="menu_toggle(menu_type)" class="pointer-events-auto fixed top-0 bottom-0 right-0 left-0 w-full h-full bg-gray-800/30 backdrop-blur-md z-40"></div>
    </Transition>

</template>

<script>
    import Logo from '@/components/logo.vue';
    //icons
    import MenuIcon from 'vue-material-design-icons/Menu.vue';
    import MagnifyIcon from 'vue-material-design-icons/Magnify.vue';
    import InstagramIcon from 'vue-material-design-icons/Instagram.vue';
    import CloseIcon from 'vue-material-design-icons/Close.vue';

    export default {
        name: 'Navbar',
        props: ['use_bg', 'logo_size', 'logo_mobile_size'],
        // emits: [],
        data() {
            return {
                menu_is_open: false,
                menu_type: 'menu',
                search_text: '',
                use_menu_padding: true,
                is_busy: false,
            }
        },
        components: {
            Logo,
            // icons
            MenuIcon,
            MagnifyIcon,
            InstagramIcon,
            CloseIcon,
        },
        computed: {
            get_content_categories_chunks() {
                if(this.$store.state.base_data && this.$store.state.base_data.content_categories && this.$store.state.base_data.content_categories.length > 0)
                {
                    const chunkSize = 3;
                    let chunks = [];
                    for (let i = 0; i < this.$store.state.base_data.content_categories.length; i += chunkSize) {
                        chunks.push(this.$store.state.base_data.content_categories.slice(i, i + chunkSize));
                    }
                    return chunks;
                }
                return [];
            },
            get_artist_categories_chunks() {
                if(this.$store.state.base_data && this.$store.state.base_data.artist_categories && this.$store.state.base_data.artist_categories.length > 0)
                {
                    const chunkSize = 6;
                    let chunks = [];
                    for (let i = 0; i < this.$store.state.base_data.artist_categories.length; i += chunkSize) {
                        chunks.push(this.$store.state.base_data.artist_categories.slice(i, i + chunkSize));
                    }
                    return chunks;
                }
                return [];
            },
        },
        mounted() {
            // this.check_menu_outside_click();
            document.getElementById('wrapper').addEventListener('scroll', this.scrollFunction, false);
        },
        methods: {
            menu_toggle(_type) {
                if(this.menu_is_open && _type != this.menu_type)
                {
                    this.menu_type = _type;
                }
                else
                {
                    this.menu_type = _type;
                    if(!this.menu_is_open)
                    {
                        this.menu_is_open = true;
                        this.is_busy = true;
                    }
                    else
                    {
                        this.menu_is_open = false;
                        setTimeout(() => {
                            this.is_busy = false;
                        }, 400);
                    }
                    let current_scroll_pos = document.getElementById('wrapper').scrollTop;
                    // document.getElementById('navbar').style.padding = current_scroll_pos <= 50 || this.menu_is_open ? '28px' : '24px';
                    this.use_menu_padding = current_scroll_pos <= 50 || this.menu_is_open ? true : false;
                }
            },
            scrollFunction() {
                let current_scroll_pos = document.getElementById('wrapper').scrollTop;
                // document.getElementById('navbar').style.padding = current_scroll_pos <= 50 || this.menu_is_open ? '28px' : '24px';
                this.use_menu_padding = current_scroll_pos <= 50 || this.menu_is_open ? true : false;
            },
            search_submit() {
                this.$router.push({ path: '/search/', query: { stext: this.search_text } }).then(() => { this.$router.go(0) });
            },
        }
    }
</script>
