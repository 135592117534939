<template>
    <div style="font-family: Questrial;" v-show="this.$store.state.is_loading" class="text-gray-600 backdrop-blur-xl bg-white/30 z-[999999] w-screen h-screen top-0 bottom-0 left-0 right-0 fixed flex flex-col items-center justify-center">
        <div class="text-4xl md:text-6xl"><span class="text-red-400 font-bold">HISTORY</span> <span class="font-normal">OF COLORS</span></div>
        <svg class="w-16 h-16 animate-spin mt-6 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" /></svg>
        <p class="whitespace-nowrap mt-4" dir="ltr">Loading...</p>
    </div>
</template>

<script>
    export default {
        name: 'LoadingComponent',
    }
</script>
