<template>
    
    <div style="font-family: Questrial;" dir="ltr">
        <div v-show="dialog_visible"
            x-transition:enter="transition duration-300 ease-out"
            x-transition:enter-start="translate-y-4 opacity-0 sm:translate-y-0 sm:scale-95"
            x-transition:enter-end="translate-y-0 opacity-100 sm:scale-100"
            x-transition:leave="transition duration-150 ease-in"
            x-transition:leave-start="translate-y-0 opacity-100 sm:scale-100"
            x-transition:leave-end="translate-y-4 opacity-0 sm:translate-y-0 sm:scale-95"
            class="fixed flex flex-row items-center justify-center z-10 w-full h-full top-0 bottom-0 left-0 right-0 bg-gray-600 bg-opacity-70 overflow-hidden"
            aria-labelledby="modal-title" role="dialog" aria-modal="true">
            <div class="flex flex-row items-center justify-center min-h-screen w-full">
                <div id="dialog_panel" class="relative flex flex-col items-center justify-start max-h-[600px] overflow-y-auto overflow-x-hidden transition-all transform bg-white rounded-lg shadow-xl max-w-[80%] w-full">
                    <div class="flex flex-col items-center justify-center gap-2 w-full p-6 sticky bg-base-100 top-0 left-0 right-0 z-50">
                        <p class="text-2xl w-full text-center font-semibold text-gray-700">Interview Data</p>
                        <div class="flex flex-row w-full items-center justify-center gap-2 mt-2">
                            <div class="flex flex-col md:flex-row items-center justify-center gap-2">
                                <button @click="close_dialog" class="btn md:text-lg btn-sm md:btn-md">
                                    Close
                                </button>
                                <button @click="edit_button_click" class="btn md:text-lg btn-sm md:btn-md" :class="{'btn-primary': edit_mode}">
                                    <p v-if="!edit_mode">Edit</p>
                                    <p v-else>Save</p>
                                </button>
                            </div>
                            <div class="divider divider-horizontal"></div>
                            <div class="flex flex-col md:flex-row items-center justify-center gap-2">
                                <button @click="change_publish_status(-1)" v-if="item" class="btn btn-error text-white md:text-lg btn-sm md:btn-md">
                                    Reject data
                                </button>
                                <button @click="change_publish_status(1)" v-if="item" class="btn btn-success text-white md:text-lg btn-sm md:btn-md">
                                    Accept data
                                </button>
                            </div>
                        </div>
                        <p class="text-base w-full text-center text-gray-800" v-if="item">Views : {{ item.views_count }}</p>
                    </div>
                    <div class="flex flex-col items-center justify-center w-full p-6" v-if="item">
                        <!-- user informations -->
                        <p class="text-lg md:text-xl text-gray-700 text-left w-full">User informations</p>
                        <div class="flex flex-col items-center justify-center w-full border-l-[1.5px] text-gray-700 text-base md:text-lg mt-2">
                            <p class="ml-8 text-gray-600 text-left w-full">Name:</p>
                            <p class="ml-8 text-gray-800 text-left w-full">{{ item.person.name }}</p>
                            <p class="ml-8 text-gray-600 text-left w-full mt-4">Email:</p>
                            <p class="ml-8 text-gray-800 text-left w-full">{{ item.person.email }}</p>
                            <p class="ml-8 text-gray-600 text-left w-full mt-4">Invite Code:</p>
                            <p class="ml-8 text-gray-800 text-left w-full">{{ item.invite_code.invite_code }}</p>
                        </div>
                        <div class="divider"></div>
                        <!-- content category -->
                        <p class="text-lg md:text-xl text-gray-700 font-bold text-left w-full">Content category</p>
                        <div class="flex flex-col items-start justify-center w-full border-l-[1.5px] text-base md:text-lg mt-2">
                            <p class="ml-8 text-gray-600 text-left w-full">Category:</p>
                            <p class="ml-8 text-gray-800 text-left w-full">{{ item.content_category ? item.content_category.name : '-' }}</p>
                        </div>
                        <div class="divider"></div>
                        <!-- artist category -->
                        <p class="text-lg md:text-xl text-gray-700 font-bold text-left w-full">Artist category</p>
                        <div class="flex flex-col items-start justify-center w-full border-l-[1.5px] text-base md:text-lg mt-2">
                            <p class="ml-8 text-gray-600 text-left w-full">Category:</p>
                            <p class="ml-8 text-gray-800 text-left w-full">{{ item.artist_category ? item.artist_category.name : '-' }}</p>
                            <p class="ml-8 text-gray-600 text-left w-full mt-4">Custom category:</p>
                            <p class="ml-8 text-gray-800 text-left w-full">{{ item.custom_artist_category ? item.custom_artist_category : '-' }}</p>
                        </div>
                        <div class="divider"></div>
                        <!-- starter -->
                        <p class="text-lg md:text-xl text-gray-700 font-bold text-left w-full">Starter</p>
                        <div class="flex flex-col items-start justify-center w-full border-l-[1.5px] text-base md:text-lg mt-2">
                            <p class="ml-8 text-gray-600 text-left w-full">Starter text:</p>
                            <p class="ml-8 text-gray-800 text-left w-full">{{ item.starter && item.starter.length > 0 ? item.starter[0].text.replace('{name}', item.person.name) : '-' }}</p>
                        </div>
                        <div class="divider"></div>
                        <!-- questions -->
                        <p class="text-lg md:text-xl text-gray-700 font-bold text-left w-full">Questions</p>
                        <div class="flex flex-col items-start justify-center w-full border-l-[1.5px] text-base md:text-lg mt-2">
                            <!-- question 1 -->
                            <p class="ml-8 text-gray-600 text-left w-full">Question 1:</p>
                            <p class="ml-8 text-gray-800 text-left w-full truncate">{{ item.question_1.name }}</p>
                            <p class="ml-8 text-gray-600 text-left w-full mt-4">Question 1 answer:</p>
                            <p class="ml-8 text-gray-800 text-left w-full" v-if="!edit_mode">{{ item.question_1_answer }}</p>
                            <div class="flex flex-row items-center justify-center w-full pl-8" v-else>
                                <textarea rows="8" type="text" v-model="item.question_1_answer" :disabled="!edit_mode" class="w-full textarea textarea-bordered text-base"></textarea>
                            </div>
                            <!-- question 2 -->
                            <p class="ml-8 text-gray-600 text-left w-full mt-4">Question 2:</p>
                            <p class="ml-8 text-gray-800 text-left w-full truncate">{{ item.question_2.name }}</p>
                            <p class="ml-8 text-gray-600 text-left w-full mt-4">Question 2 answer:</p>
                            <p class="ml-8 text-gray-800 text-left w-full" v-if="!edit_mode">{{ item.question_2_answer }}</p>
                            <div class="flex flex-row items-center justify-center w-full pl-8" v-else>
                                <textarea rows="8" type="text" v-model="item.question_2_answer" :disabled="!edit_mode" class="w-full textarea textarea-bordered text-base"></textarea>
                            </div>
                            <!-- question 3 -->
                            <p class="ml-8 text-gray-600 text-left w-full mt-4">Question 3:</p>
                            <p class="ml-8 text-gray-800 text-left w-full truncate">{{ item.question_3.name }}</p>
                            <p class="ml-8 text-gray-600 text-left w-full mt-4">Question 3 answer:</p>
                            <p class="ml-8 text-gray-800 text-left w-full" v-if="!edit_mode">{{ item.question_3_answer }}</p>
                            <div class="flex flex-row items-center justify-center w-full pl-8" v-else>
                                <textarea rows="8" type="text" v-model="item.question_3_answer" :disabled="!edit_mode" class="w-full textarea textarea-bordered text-base"></textarea>
                            </div>
                            <!-- about question -->
                            <p class="ml-8 text-gray-600 text-left w-full mt-4">About:</p>
                            <p class="ml-8 text-gray-800 text-left w-full" v-if="!edit_mode">{{ item.about_answer }}</p>
                            <div class="flex flex-row items-center justify-center w-full pl-8" v-else>
                                <textarea rows="8" type="text" v-model="item.about_answer" :disabled="!edit_mode" class="w-full textarea textarea-bordered text-base"></textarea>
                            </div>
                        </div>
                        <div class="divider"></div>
                        <!-- social media -->
                        <p class="text-lg md:text-xl text-gray-700 font-bold text-left w-full">Social media</p>
                        <div class="flex flex-col items-center justify-center w-full border-l-[1.5px] text-base md:text-lg mt-2">
                            <!-- website -->
                            <p class="ml-8 text-gray-600 text-left w-full">Website:</p>
                            <p class="ml-8 text-gray-800 text-left w-full truncate" v-if="!edit_mode">{{ item.website ? item.website : '-' }}</p>
                            <div class="flex flex-row items-center justify-center w-full pl-8" v-else>
                                <input type="text" v-model="item.website" :disabled="!edit_mode" class="w-full input input-bordered text-base">
                            </div>
                            <!-- instagram -->
                            <p class="ml-8 text-gray-600 text-left w-full mt-4">Instagram:</p>
                            <p class="ml-8 text-gray-800 text-left w-full truncate" v-if="!edit_mode">{{ item.instagram ? item.instagram : '-' }}</p>
                            <div class="flex flex-row items-center justify-center w-full pl-8" v-else>
                                <input type="text" v-model="item.instagram" :disabled="!edit_mode" class="w-full input input-bordered text-base">
                            </div>
                            <!-- linkedin -->
                            <p class="ml-8 text-gray-600 text-left w-full mt-4">Linkedin:</p>
                            <p class="ml-8 text-gray-800 text-left w-full truncate" v-if="!edit_mode">{{ item.linkedin ? item.linkedin : '-' }}</p>
                            <div class="flex flex-row items-center justify-center w-full pl-8" v-else>
                                <input type="text" v-model="item.linkedin" :disabled="!edit_mode" class="w-full input input-bordered text-base">
                            </div>
                            <!-- twitter -->
                            <p class="ml-8 text-gray-600 text-left w-full mt-4">Twitter:</p>
                            <p class="ml-8 text-gray-800 text-left w-full truncate" v-if="!edit_mode">{{ item.twitter ? item.twitter : '-' }}</p>
                            <div class="flex flex-row items-center justify-center w-full pl-8" v-else>
                                <input type="text" v-model="item.twitter" :disabled="!edit_mode" class="w-full input input-bordered text-base">
                            </div>
                            <!-- facebook -->
                            <p class="ml-8 text-gray-600 text-left w-full mt-4">Facebook:</p>
                            <p class="ml-8 text-gray-800 text-left w-full truncate" v-if="!edit_mode">{{ item.facebook ? item.facebook : '-' }}</p>
                            <div class="flex flex-row items-center justify-center w-full pl-8" v-else>
                                <input type="text" v-model="item.facebook" :disabled="!edit_mode" class="w-full input input-bordered text-base">
                            </div>
                            <!-- yelp -->
                            <p class="ml-8 text-gray-600 text-left w-full mt-4">Yelp:</p>
                            <p class="ml-8 text-gray-800 text-left w-full truncate" v-if="!edit_mode">{{ item.yelp ? item.yelp : '-' }}</p>
                            <div class="flex flex-row items-center justify-center w-full pl-8" v-else>
                                <input type="text" v-model="item.yelp" :disabled="!edit_mode" class="w-full input input-bordered text-base">
                            </div>
                            <!-- youtube -->
                            <p class="ml-8 text-gray-600 text-left w-full mt-4">Youtube:</p>
                            <p class="ml-8 text-gray-800 text-left w-full truncate" v-if="!edit_mode">{{ item.youtube ? item.youtube : '-' }}</p>
                            <div class="flex flex-row items-center justify-center w-full pl-8" v-else>
                                <input type="text" v-model="item.youtube" :disabled="!edit_mode" class="w-full input input-bordered text-base">
                            </div>
                            <!-- other_links -->
                            <p class="ml-8 text-gray-600 text-left w-full mt-4">Other links:</p>
                            <p class="ml-8 text-gray-800 text-left w-full truncate" v-if="!edit_mode">{{ item.other_links ? item.other_links : '-' }}</p>
                            <div class="flex flex-row items-center justify-center w-full pl-8" v-else>
                                <textarea rows="8" type="text" v-model="item.other_links" :disabled="!edit_mode" class="w-full textarea textarea-bordered text-base"></textarea>
                            </div>
                        </div>
                        <div class="divider"></div>
                        <!-- introducing people -->
                        <p class="text-lg md:text-xl text-gray-700 font-bold text-left w-full">Introducing people</p>
                        <div class="flex flex-col items-center justify-center w-full border-l-[1.5px] text-base md:text-lg mt-2">
                            <!-- person 1 -->
                            <p class="ml-8 text-gray-600 text-left w-full">Name:</p>
                            <p class="ml-8 text-gray-800 text-left font-semibold w-full truncate">{{ item.nominate_1 ? item.nominate_1.name : '-' }}</p>
                            <p class="ml-8 text-gray-600 text-left w-full mt-2">Email:</p>
                            <p class="ml-8 text-gray-800 text-left font-semibold w-full truncate">{{ item.nominate_1 ? item.nominate_1.email : '-' }}</p>
                            <p class="ml-8 text-gray-600 text-left w-full mt-2">Website:</p>
                            <p class="ml-8 text-gray-800 text-left font-semibold w-full truncate">{{ item.nominate_1 && item.nominate_1.website ? item.nominate_1.website : '-' }}</p>
                            <p class="ml-8 text-gray-600 text-left w-full mt-2">Social media:</p>
                            <p class="ml-8 text-gray-800 text-left font-semibold w-full truncate">{{ item.nominate_1 && item.nominate_1.social_media ? item.nominate_1.social_media : '-' }}</p>
                            <p class="ml-8 text-gray-600 text-left w-full mt-2">About:</p>
                            <p class="ml-8 text-gray-800 text-left font-semibold w-full truncate">{{ item.nominate_1 && item.nominate_1.about_person ? item.nominate_1.about_person : '-' }}</p>
                            <div class="divider mx-6"></div>
                            <!-- person 2 -->
                            <p class="ml-8 text-gray-600 text-left w-full">Name:</p>
                            <p class="ml-8 text-gray-800 text-left w-full truncate">{{ item.nominate_2 ? item.nominate_2.name : '-' }}</p>
                            <p class="ml-8 text-gray-600 text-left w-full mt-2">Email:</p>
                            <p class="ml-8 text-gray-800 text-left w-full truncate">{{ item.nominate_2 ? item.nominate_2.email : '-' }}</p>
                            <p class="ml-8 text-gray-600 text-left w-full mt-2">Website:</p>
                            <p class="ml-8 text-gray-800 text-left w-full truncate">{{ item.nominate_2 && item.nominate_2.website ? item.nominate_2.website : '-' }}</p>
                            <p class="ml-8 text-gray-600 text-left w-full mt-2">Social media:</p>
                            <p class="ml-8 text-gray-800 text-left w-full truncate">{{ item.nominate_2 && item.nominate_2.social_media ? item.nominate_2.social_media : '-' }}</p>
                            <p class="ml-8 text-gray-600 text-left w-full mt-2">About:</p>
                            <p class="ml-8 text-gray-800 text-left w-full truncate">{{ item.nominate_2 && item.nominate_2.about_person ? item.nominate_2.about_person : '-' }}</p>
                        </div>
                        <div class="divider"></div>
                        <!-- images -->
                        <p class="text-lg md:text-xl text-gray-700 font-bold text-left w-full">Images</p>
                        <p class="text-md md:text-lg text-gray-500 text-left w-full"><span class="text-red-500">*</span> Click on the photos to see them in full size</p>
                        <div class="flex flex-col items-start justify-start w-full border-l-[1.5px] text-base md:text-lg mt-2">
                            <!-- personal image -->
                            <div class="flex flex-col items-start justify-center p-4">
                                <div class="flex flex-row items-center justify-between w-80 bg-slate-600 rounded-t-lg p-2">
                                    <p class="text-white text-sm">Personal image</p>
                                    <input v-show="edit_mode" v-model="item.personal_image_enable" type="checkbox" class="toggle toggle-info" />
                                    <svg v-show="!edit_mode && item.personal_image_enable" class="w-12 h-12 stroke-current text-info" stroke-width="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17 13C17.5523 13 18 12.5523 18 12C18 11.4477 17.5523 11 17 11C16.4477 11 16 11.4477 16 12C16 12.5523 16.4477 13 17 13Z" fill="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M17 17H7C4.23858 17 2 14.7614 2 12C2 9.23858 4.23858 7 7 7H17C19.7614 7 22 9.23858 22 12C22 14.7614 19.7614 17 17 17Z" stroke-width="1.5"></path></svg>
                                    <svg v-show="!edit_mode && !item.personal_image_enable" class="w-12 h-12 stroke-current text-error" stroke-width="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7 13C7.55228 13 8 12.5523 8 12C8 11.4477 7.55228 11 7 11C6.44772 11 6 11.4477 6 12C6 12.5523 6.44772 13 7 13Z" fill="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M17 17H7C4.23858 17 2 14.7614 2 12C2 9.23858 4.23858 7 7 7H17C19.7614 7 22 9.23858 22 12C22 14.7614 19.7614 17 17 17Z" stroke-width="1.5"></path></svg>
                                </div>
                                <img @click="show_image_in_full_size(base_url + item.personal_image)" class="md:max-w-80 md:max-h-80 w-80 h-80 aspect-square object-cover cursor-pointer" :src="base_url + item.personal_image">
                            </div>
                            <div class="divider mx-6"></div>
                            <!-- additional images -->
                            <div class="w-full flex flex-row items-start justify-start overflow-y-auto overflow-x-hidden flex-wrap gap-2 p-4" v-if="item.additional_image_1">
                                <div class="flex flex-col items-start justify-center">
                                    <div class="flex flex-row items-center justify-between w-80 bg-slate-600 rounded-t-lg p-2">
                                        <p class="text-white text-sm">Additional image 1</p>
                                        <input v-show="edit_mode" v-model="item.additional_image_1_enable" type="checkbox" class="toggle toggle-info" />
                                        <svg v-show="!edit_mode && item.additional_image_1_enable" class="w-12 h-12 stroke-current text-info" stroke-width="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17 13C17.5523 13 18 12.5523 18 12C18 11.4477 17.5523 11 17 11C16.4477 11 16 11.4477 16 12C16 12.5523 16.4477 13 17 13Z" fill="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M17 17H7C4.23858 17 2 14.7614 2 12C2 9.23858 4.23858 7 7 7H17C19.7614 7 22 9.23858 22 12C22 14.7614 19.7614 17 17 17Z" stroke-width="1.5"></path></svg>
                                        <svg v-show="!edit_mode && !item.additional_image_1_enable" class="w-12 h-12 stroke-current text-error" stroke-width="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7 13C7.55228 13 8 12.5523 8 12C8 11.4477 7.55228 11 7 11C6.44772 11 6 11.4477 6 12C6 12.5523 6.44772 13 7 13Z" fill="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M17 17H7C4.23858 17 2 14.7614 2 12C2 9.23858 4.23858 7 7 7H17C19.7614 7 22 9.23858 22 12C22 14.7614 19.7614 17 17 17Z" stroke-width="1.5"></path></svg>
                                    </div>
                                    <img @click="show_image_in_full_size(base_url + item.additional_image_1)" class="md:max-w-80 md:max-h-80 aspect-square w-80 h-80 object-cover cursor-pointer" :src="base_url + item.additional_image_1">
                                </div>
                                <div class="flex flex-col items-start justify-center">
                                    <div class="flex flex-row items-center justify-between w-80 bg-slate-600 rounded-t-lg p-2">
                                        <p class="text-white text-sm">Additional image 2</p>
                                        <input v-show="edit_mode" v-model="item.additional_image_2_enable" type="checkbox" class="toggle toggle-info" />
                                        <svg v-show="!edit_mode && item.additional_image_2_enable" class="w-12 h-12 stroke-current text-info" stroke-width="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17 13C17.5523 13 18 12.5523 18 12C18 11.4477 17.5523 11 17 11C16.4477 11 16 11.4477 16 12C16 12.5523 16.4477 13 17 13Z" fill="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M17 17H7C4.23858 17 2 14.7614 2 12C2 9.23858 4.23858 7 7 7H17C19.7614 7 22 9.23858 22 12C22 14.7614 19.7614 17 17 17Z" stroke-width="1.5"></path></svg>
                                        <svg v-show="!edit_mode && !item.additional_image_2_enable" class="w-12 h-12 stroke-current text-error" stroke-width="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7 13C7.55228 13 8 12.5523 8 12C8 11.4477 7.55228 11 7 11C6.44772 11 6 11.4477 6 12C6 12.5523 6.44772 13 7 13Z" fill="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M17 17H7C4.23858 17 2 14.7614 2 12C2 9.23858 4.23858 7 7 7H17C19.7614 7 22 9.23858 22 12C22 14.7614 19.7614 17 17 17Z" stroke-width="1.5"></path></svg>
                                    </div>
                                    <img @click="show_image_in_full_size(base_url + item.additional_image_2)" class="md:max-w-80 md:max-h-80 aspect-square w-80 h-80 object-cover cursor-pointer" :src="base_url + item.additional_image_2">
                                </div>
                                <div class="flex flex-col items-start justify-center">
                                    <div class="flex flex-row items-center justify-between w-80 bg-slate-600 rounded-t-lg p-2">
                                        <p class="text-white text-sm">Additional image 3</p>
                                        <input v-show="edit_mode" v-model="item.additional_image_3_enable" type="checkbox" class="toggle toggle-info" />
                                        <svg v-show="!edit_mode && item.additional_image_3_enable" class="w-12 h-12 stroke-current text-info" stroke-width="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17 13C17.5523 13 18 12.5523 18 12C18 11.4477 17.5523 11 17 11C16.4477 11 16 11.4477 16 12C16 12.5523 16.4477 13 17 13Z" fill="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M17 17H7C4.23858 17 2 14.7614 2 12C2 9.23858 4.23858 7 7 7H17C19.7614 7 22 9.23858 22 12C22 14.7614 19.7614 17 17 17Z" stroke-width="1.5"></path></svg>
                                        <svg v-show="!edit_mode && !item.additional_image_3_enable" class="w-12 h-12 stroke-current text-error" stroke-width="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7 13C7.55228 13 8 12.5523 8 12C8 11.4477 7.55228 11 7 11C6.44772 11 6 11.4477 6 12C6 12.5523 6.44772 13 7 13Z" fill="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M17 17H7C4.23858 17 2 14.7614 2 12C2 9.23858 4.23858 7 7 7H17C19.7614 7 22 9.23858 22 12C22 14.7614 19.7614 17 17 17Z" stroke-width="1.5"></path></svg>
                                    </div>
                                    <img @click="show_image_in_full_size(base_url + item.additional_image_3)" class="md:max-w-80 md:max-h-80 aspect-square w-80 h-80 object-cover cursor-pointer" :src="base_url + item.additional_image_3">
                                </div>
                                <div class="flex flex-col items-start justify-center">
                                    <div class="flex flex-row items-center justify-between w-80 bg-slate-600 rounded-t-lg p-2">
                                        <p class="text-white text-sm">Additional image 4</p>
                                        <input v-show="edit_mode" v-model="item.additional_image_4_enable" type="checkbox" class="toggle toggle-info" />
                                        <svg v-show="!edit_mode && item.additional_image_4_enable" class="w-12 h-12 stroke-current text-info" stroke-width="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17 13C17.5523 13 18 12.5523 18 12C18 11.4477 17.5523 11 17 11C16.4477 11 16 11.4477 16 12C16 12.5523 16.4477 13 17 13Z" fill="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M17 17H7C4.23858 17 2 14.7614 2 12C2 9.23858 4.23858 7 7 7H17C19.7614 7 22 9.23858 22 12C22 14.7614 19.7614 17 17 17Z" stroke-width="1.5"></path></svg>
                                        <svg v-show="!edit_mode && !item.additional_image_4_enable" class="w-12 h-12 stroke-current text-error" stroke-width="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7 13C7.55228 13 8 12.5523 8 12C8 11.4477 7.55228 11 7 11C6.44772 11 6 11.4477 6 12C6 12.5523 6.44772 13 7 13Z" fill="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M17 17H7C4.23858 17 2 14.7614 2 12C2 9.23858 4.23858 7 7 7H17C19.7614 7 22 9.23858 22 12C22 14.7614 19.7614 17 17 17Z" stroke-width="1.5"></path></svg>
                                    </div>
                                    <img @click="show_image_in_full_size(base_url + item.additional_image_4)" class="md:max-w-80 md:max-h-80 aspect-square w-80 h-80 object-cover cursor-pointer" :src="base_url + item.additional_image_4">
                                </div>
                                <div class="flex flex-col items-start justify-center">
                                    <div class="flex flex-row items-center justify-between w-80 bg-slate-600 rounded-t-lg p-2">
                                        <p class="text-white text-sm">Additional image 5</p>
                                        <input v-show="edit_mode" v-model="item.additional_image_5_enable" type="checkbox" class="toggle toggle-info" />
                                        <svg v-show="!edit_mode && item.additional_image_5_enable" class="w-12 h-12 stroke-current text-info" stroke-width="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17 13C17.5523 13 18 12.5523 18 12C18 11.4477 17.5523 11 17 11C16.4477 11 16 11.4477 16 12C16 12.5523 16.4477 13 17 13Z" fill="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M17 17H7C4.23858 17 2 14.7614 2 12C2 9.23858 4.23858 7 7 7H17C19.7614 7 22 9.23858 22 12C22 14.7614 19.7614 17 17 17Z" stroke-width="1.5"></path></svg>
                                        <svg v-show="!edit_mode && !item.additional_image_5_enable" class="w-12 h-12 stroke-current text-error" stroke-width="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7 13C7.55228 13 8 12.5523 8 12C8 11.4477 7.55228 11 7 11C6.44772 11 6 11.4477 6 12C6 12.5523 6.44772 13 7 13Z" fill="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M17 17H7C4.23858 17 2 14.7614 2 12C2 9.23858 4.23858 7 7 7H17C19.7614 7 22 9.23858 22 12C22 14.7614 19.7614 17 17 17Z" stroke-width="1.5"></path></svg>
                                    </div>
                                    <img @click="show_image_in_full_size(base_url + item.additional_image_5)" class="md:max-w-80 md:max-h-80 aspect-square w-80 h-80 object-cover cursor-pointer" :src="base_url + item.additional_image_5">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="flex flex-col items-center justify-center w-full p-6" v-if="!item">
                        <NoDataFound />
                    </div>
                </div>
            </div>
        </div>
    </div>

    <FullScreenImage ref="full_image_component" />

</template>

<script>
import axios from 'axios';
import FullScreenImage from '@/components/full-screen-image.vue';
import NoDataFound from '@/components/no-data-found.vue';

export default {
    name: 'InterviewDetailsDialog',
    data() {
        return {
            dialog_visible: false,
            item: null,
            base_url: '',
            edit_mode: false,
        }
    },
    components: {
        FullScreenImage,
        NoDataFound,
    },
    mounted() {
        this.awake();
    },
    methods: {
        // awake
        awake() {
            this.base_url = this.$store.state.base_url;
        },
        // show dialog
        show_dialog(_item_data) {
            if(_item_data)
            {
                this.item = _item_data;
                this.dialog_visible = true;
                if(document.getElementById('dialog_panel'))
                    document.getElementById('dialog_panel').scrollTo({ top: 0, behavior: 'smooth' });
            }
        },
        // close dialog
        close_dialog() {
            this.$store.state.is_loading = false;
            this.edit_mode = false;
            this.dialog_visible = false;
        },
        // show images in full screen
        show_image_in_full_size(_url) {
            this.$refs.full_image_component.show_component(_url);
        },
        // change data publish status
        async change_publish_status(_status) {
            this.$store.state.is_loading = true;
            await axios.post('/api/v1/data/actions/', {request_type: 'change_publish_status', data_type: 'interview_data', status: _status, id: this.item.id, token: this.$store.state.auth_token} , this.$store.state.axios_config)
                .then(response => {
                    this.item.publish_status = _status;
                    this.$store.dispatch('addToast', {msg: 'Changes were recorded', type: 'success'});
                    setTimeout(() => {
                        this.close_dialog();
                    }, 500);
                })
                .catch(error => {
                    if(error.response.state == 403)
                        this.$store.dispatch('addToast', {msg: 'You do not have access to this information', type: 'error'});
                    else if(error.response.state == 404)
                        this.$store.dispatch('addToast', {msg: 'No information found', type: 'error'});
                    else
                        this.$store.dispatch('addToast', {msg: 'An error has occurred on the server side, please contact support', type: 'error'});
                    if(this.$store.state.is_debug)
                        console.log(error);
                setTimeout(() => {
                        this.$store.state.is_loading = false;
                }, 500);
            });
        },
        async edit_button_click() {
            if(!this.edit_mode)
            {
                this.edit_mode = true;
            }
            else
            {
                this.$store.state.is_loading = true;
                this.item.request_type = 'edit';
                this.item.content_type = 'interview_data';
                await axios.post('/api/v1/data/actions/', {form_data: this.item, token: this.$store.state.auth_token}, this.$store.state.axios_config)
                    .then(response => {
                        this.admin_data = response.data;
                        this.$store.dispatch('addToast', {msg: 'Changes saved', type: 'success'});
                        this.edit_mode = false;
                    })
                    .catch(error => {
                        if(error.response.state == 403)
                            this.$store.dispatch('addToast', {msg: 'You do not have access to this information', type: 'error'});
                        else if(error.response.state == 404)
                            this.$store.dispatch('addToast', {msg: 'No information found', type: 'error'});
                        else
                            this.$store.dispatch('addToast', {msg: 'An error has occurred on the server side, please contact support', type: 'error'});
                        if(this.$store.state.is_debug)
                            console.log(error);
                });
                setTimeout(() => {
                        this.$store.state.is_loading = false;
                }, 500);
            }
        }
    },
}
</script>
