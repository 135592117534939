<template>

    <div class="w-full">
        <div class="relative flex flex-col items-center justify-start w-full bg-base-100 overflow-y-auto">
            <Navbar logo_size="small" logo_mobile_size="tiny" :use_bg="false" />
            <LandingPageCards />
            <div class="relative p-10 flex flex-col items-center justify-start w-full bg-base-100 overflow-y-auto gap-10">
                <LandingPageNewPosts />
                <div class="divider"></div>
                <LandingPagePodcast />
                <LandingPageHero />
                <LandingPagePerspectives />
            </div>
        </div>
        <Footer logo_size="normal" logo_mobile_size="small" class="p-10"></Footer>
    </div>

</template>

<script>
import Navbar from '@/components/navbar.vue';
import Footer from '@/components/footer.vue';
import LandingPageCards from '@/components/landing-page-cards.vue';
import LandingPageNewPosts from '@/components/landing-page-new-posts.vue';
import LandingPagePodcast from '@/components/landing-page-podcast.vue';
import LandingPageHero from '@/components/landing-page-hero.vue';
import LandingPageLatestPopular from '@/components/landing-page-latest-popular.vue';
import LandingPagePerspectives from '@/components/landing-page-perspectives.vue';

import axios from 'axios';
// icons
// import MagnifyIcon from 'vue-material-design-icons/Magnify.vue';

export default {
    name: 'Index',
    components: {
        Navbar,
        LandingPageCards,
        LandingPageNewPosts,
        LandingPagePodcast,
        LandingPageHero,
        LandingPageLatestPopular,
        LandingPagePerspectives,
        Footer,
        // icons
    },
    mounted() {
        this.awake();
    },
    methods: {
        awake() {
            document.title = 'History Of Colors - Home';
            this.refresh_data();
        },
        async refresh_data() {
            // this.$store.state.is_loading = true;
            await axios.post('/api/v1/data/get/', {type: 'base_data'} , this.$store.state.axios_config).then(response => {
                    this.$store.state.base_data = response.data.base;
                    if(this.$store.state.base_data && this.$store.state.base_data.posts && this.$store.state.base_data.posts.length > 0)
                    {
                        this.$store.state.base_data.posts = this.$store.state.base_data.posts.reverse();
                    }
                })
                .catch(error => {
                    if((error.response && error.response.status && error.response.status == 404) || (error.request && error.request.status && error.request.status == 404))
                        this.$store.dispatch('addToast', {msg: 'No information found', type: 'error'});
                    else
                        this.$store.dispatch('addToast', {msg: 'An error has occurred on the server side, please contact support', type: 'error'});
                    if(this.$store.state.is_debug)
                        console.log(error);
                });
            // setTimeout(() => {
            //     this.$store.state.is_loading = false;
            // }, 500);
        },
    },
}
</script>
