<template>
    <!-- pc view -->
    <tr style="font-family: Questrial;" v-if="item && !is_mobile">
        <td class="text-center text-md" :class="{'border-l-2 border-info bg-info/10': item.publish_status == 0, 'border-l-2 border-success bg-success/10': item.publish_status == 1, 'border-l-2 border-error bg-error/10': item.publish_status == -1}">{{ index }}</td>
        <td class="text-center">{{ item.person.name }}</td>
        <td class="hidden md:table-cell text-center">{{ item.person.email }}</td>
        <td class="text-center">{{ item.content_category ? item.content_category.name : '-' }}</td>
        <td class="text-center">{{ item.artist_category ? item.artist_category.name : (item.custom_artist_category ? item.custom_artist_category : '-') }}</td>
        <td class="hidden md:table-cell text-center">{{ item.views_count }}</td>
        <td class="text-center">
            <div class="flex flex-row items-center justify-center gap-1 md:gap-2">
                <div class="tooltip" :data-tip="'Publication status : ' + (item.publish_status == 1 ? 'Published' : (item.publish_status == -1 ? 'Unpublished': 'Unknown'))">
                    <div class="flex flex-row items-center justify-center px-3 h-8 min-h-8">
                        <div class="rounded-full w-[10px] h-[10px] ring ring-offset-2" :class="{'bg-green-500 ring-green-800': item.publish_status == 1, 'bg-red-500 ring-red-800': item.publish_status == -1, 'bg-blue-500 ring-blue-800': item.publish_status == 0}"></div>
                    </div>
                </div>
                <div class="tooltip" data-tip="Open the interview details panel">
                    <button @click="this.$emit('details_dialog', item)" class="btn btn-square btn-ghost btn-sm">
                        <BadgeAccountIcon :size="24" class="pointer-events-none text-info" />
                    </button>
                </div>
                <div class="tooltip" data-tip="Delete information">
                    <button @click="this.$emit('delete_item', 'interview_data', item.id)" class="btn btn-square btn-ghost btn-sm">
                        <DeleteIcon :size="24" class="pointer-events-none text-error" />
                    </button>
                </div>
            </div>
        </td>
    </tr>

    <!-- mobile view -->
    <div class="flex flex-col w-full items-center justify-center card shadow border rounded-lg p-4 text-xs" style="font-family: Questrial;" v-if="item && is_mobile">
        <div class="flex flex-row w-full items-center justify-center gap-2">
            <!-- name -->
            <div class="flex flex-col items-center w-full justify-center gap-1">
                <p class="text-gray-500 w-full text-left">Name:</p>
                <p class="w-full text-left">{{ item.person.name }}</p>
            </div>
            <!-- divider -->
            <div class="divider divider-horizontal"></div>
            <!-- email -->
            <div class="flex flex-col items-center w-full justify-center gap-1">
                <p class="text-gray-500 w-full text-left">Email</p>
                <p class="w-full text-left">{{ item.person.email }}</p>
            </div>
        </div>
        <div class="divider py-1 my-1"></div>
        <div class="flex flex-row w-full items-center justify-center gap-2 mt-2">
            <!-- content and artist category -->
            <div class="flex flex-col items-center w-full justify-center gap-1">
                <p class="text-gray-500 w-full text-left">Content Category</p>
                <p class="w-full text-left">{{ item.content_category ? item.content_category.name : '-' }}</p>
                <p class="text-gray-500 w-full text-left mt-4">Artist Category</p>
                <p class="w-full text-left">{{ item.artist_category ? item.artist_category.name : (item.custom_artist_category ? item.custom_artist_category : '-') }}</p>
            </div>
            <!-- divider -->
            <div class="divider divider-horizontal"></div>
            <div class="flex flex-col w-full items-center justify-center gap-2">
                <p>Actions</p>
                <!-- buttons -->
                <div class="flex flex-row items-center w-full justify-center gap-2">
                    <div class="tooltip" :data-tip="'Publication status : ' + (item.publish_status == 1 ? 'Published' : (item.publish_status == -1 ? 'Unpublished': 'Unknown'))">
                        <div class="flex flex-row items-center justify-center px-3 h-8 min-h-8">
                            <div class="rounded-full w-[10px] h-[10px] ring ring-offset-2" :class="{'bg-green-500 ring-green-800': item.publish_status == 1, 'bg-red-500 ring-red-800': item.publish_status == -1, 'bg-blue-500 ring-blue-800': item.publish_status == 0}"></div>
                        </div>
                    </div>
                    <div class="tooltip" data-tip="Open the interview details panel">
                        <button @click="this.$emit('details_dialog', item)" class="btn btn-square btn-ghost btn-sm">
                            <BadgeAccountIcon :size="18" class="pointer-events-none text-info" />
                        </button>
                    </div>
                    <div class="tooltip"  data-tip="Delete information">
                        <button @click="this.$emit('delete_item', 'interview_data', item.id)" class="btn btn-square btn-ghost btn-sm">
                            <DeleteIcon :size="18" class="pointer-events-none text-error" />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// icons
import DeleteIcon from 'vue-material-design-icons/Delete.vue';
import BadgeAccountIcon from 'vue-material-design-icons/BadgeAccount.vue';

export default {
    name: 'AdminInterviewItem',
    emits: ['delete_item', 'details_dialog'],
    props: ['index', 'item', 'is_mobile', 'search_text'],
    components: {
        // icons
        DeleteIcon,
        BadgeAccountIcon,
    },
}
</script>
