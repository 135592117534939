<template>

    <div style="font-family: Questrial;" :data-theme="this.$store.state.app_theme" :class="{'dark': this.$store.state.use_dark}">

        <div class="flex justify-center h-screen">
            <div class="hidden bg-cover lg:block lg:w-2/3">
                <div class="flex items-center h-full relative px-20 bg-gray-900 bg-opacity-40">
                    <img class="w-full h-full absolute top-0 left-0 right-0 bottom-0 object-cover" src="@/assets/img/login-bg.jpg" >
                </div>
            </div>
            <div class="flex items-center w-full max-w-md px-6 mx-auto lg:w-2/6">
                <div class="flex-1 card shadow border p-8 md:p-0 md:shadow-none md:border-none">
                    <div class="text-center">
                        <div class="flex justify-center mx-auto">
                            <div class="text-4xl md:text-6xl"><span class="text-red-400 font-semibold">HISTORY</span> <span class="font-normal">OF ART</span></div>
                        </div>
                        <p class="mt-12 text-3xl">Login</p>
                    </div>
                    <div class="mt-8">
                        <div>
                            <div class="relative" dir="ltr">
                                <label for="username" class="block mb-2 text-md">Username</label>
                                <input @keyup.enter="submit" v-model="credential_data.username" type="text" name="username" id="username" placeholder="Enter your username" class="block w-full input input-bordered pl-10" />
                                <account-icon :size="18" class="absolute left-3 top-1/2 mt-[4.5px]" />
                            </div>
                            <div class="relative mt-6" dir="ltr">
                                <label for="password" class="block mb-2 text-md">Password</label>
                                <input @keyup.enter="submit" v-model="credential_data.password" :type="password_show ? 'text' : 'password'" name="password" id="password" placeholder="Enter your password" class="block w-full input input-bordered pr-10 pl-10" />
                                <key-variant-icon :size="18" class="absolute left-3 top-1/2 mt-[4.5px]" />
                                <button @click="password_show = !password_show" class="absolute right-3 top-1/2 mt-[4.5px]">
                                    <eye-icon v-show="!password_show" :size="18" />
                                    <eye-off-icon v-show="password_show" :size="18" />
                                </button>
                            </div>
                            <div class="mt-6">
                                <button @click="submit" class="w-full btn btn-primary tracking-wide text-xl transition-colors duration-300 transform">Login</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
//icons
import AccountIcon from 'vue-material-design-icons/Account.vue';
import KeyVariantIcon from 'vue-material-design-icons/KeyVariant.vue';
import EyeIcon from 'vue-material-design-icons/Eye.vue';
import EyeOffIcon from 'vue-material-design-icons/EyeOff.vue';

export default {
    name: 'AdminLogin',
    data() {
        return {
            credential_data: {username: '', password: ''},
            password_show: false,
        }
    },
    components: {
        AccountIcon,
        KeyVariantIcon,
        EyeIcon,
        EyeOffIcon,
    },
    mounted() {
        document.title = 'History Of Colors - Login';
        this.auth_check();
    },
    methods: {
        async auth_check() {
            if(this.$store.state.is_authenticated == true)
                this.$router.push({ path: '/admin' });
            if(this.$store.state.logout_var == true)
            {
                await this.$store.dispatch('addToast', {msg: 'You have logged out of your account', type: 'success'});
                this.$store.state.logout_var = false;
            }
        },
        async submit() {
            if(this.$store.state.is_loading == true)
                return false;
            if(!this.credential_data.username)
            {
                await this.$store.dispatch('addToast', {msg: 'Username cannot be empty', type: 'error'});
                return false;
            }
            if(!this.credential_data.password)
            {
                await this.$store.dispatch('addToast', {msg: 'Password cannot be empty', type: 'error'});
                return false;
            }
            let res = false;
            this.$store.state.is_loading = true;
            await axios.post('/api/v1/auth/admin/login/', this.credential_data, this.$store.state.axios_config).then(response => {
                    if(response.data && response.data.token)
                    {
                        localStorage.setItem('user_token', response.data.token);
                        localStorage.setItem('user_login_time', Math.floor(Date.now() / 1000));
                        localStorage.setItem('user_expire', 1209600);
                        // this.$store.state.axios_config = {headers: {Authorization: 'Token ' + response.data.token}};
                        this.$store.state.auth_token = response.data.token;
                        this.$store.state.login_var = true;
                        this.$store.state.is_authenticated = true;
                        res = true;
                    }
                    else {
                        this.$store.dispatch('addToast', {msg: 'The username or password is incorrect', type: 'error'});
                    }
                }).catch((error) => {
                    console.log(error)
                    this.$store.dispatch('addToast', {msg: 'The username or password is incorrect', type: 'error'});
            });
            this.$store.state.is_loading = false;
            if(res == true)
                this.$router.push({ path: '/admin' });
        },
        async goto(_path) {
            await this.$router.push({ path: _path });
        },
    }
}
</script>
