<template>
    <Navbar :use_bg="true" logo_size="small" logo_mobile_size="small" />
    <div class="w-full p-10" style="font-family: Questrial;">
        <div class="flex flex-col md:flex-row items-start justify-center w-full">
            <!-- similar posts -->
            <div class="flex flex-col items-center justify-start md:max-w-[80%] w-full gap-4">
                <p class="text-4xl font-bold text-left w-full mb-8" v-if="items">{{ items[0].title }}</p>
                <template v-for="(item, index) in items" :key="index" v-if="items">
                    <div :id="'post-' + String(index)" class="flex w-full gap-6">
                        <router-link :id="'post-image-parent-' + String(index)" class="w-full" :to="'/post/' + item.slug">
                            <img :id="'post-image-' + String(index)" class="object-cover max-h-[600px] h-full transition-transform duration-500 ease-out group-hover:scale-110 cursor-pointer" :src="this.$store.state.base_url + item.personal_image" :alt="item.slug + '-image'" />
                        </router-link>
                        <div class="flex flex-col items-start justify-start w-full">
                            <router-link :to="'/category/' + item.content_category.slug" v-if="item.content_category">
                                <p class="text-sm font-bold text-left uppercase text-red-500">{{ item.content_category.name }}</p>
                            </router-link>
                            <router-link :to="'/artcategory/' + item.artist_category.slug" class="mt-2" v-if="item.artist_category">
                                <p class="text-sm font-bold text-left uppercase text-blue-400">{{ item.artist_category.name }}</p>
                            </router-link>
                            <p v-else class="text-sm font-bold text-left uppercase text-blue-400 mt-2">{{ item.custom_artist_category }}</p>
                            <p class="text-lg text-gray-700 text-left font-semibold line-clamp-3 mt-2 text-wrap">{{ item.short_description }}</p>
                        </div>
                    </div>
                    <div class="divider" v-if="index < items.length - 1"></div>
                </template>
            </div>
            <div class="divider md:divider-horizontal divider-vertical"></div>
            <!-- new posts -->
            <NewPostsSidebar class="md:max-w-[20%] w-full" :posts="sidebar_posts" />
            <!-- <div class="hidden md:flex flex-col items-center justify-start max-w-[20%] w-full"></div> -->
        </div>
    </div>
    <Footer logo_size="normal" logo_mobile_size="small" class="p-10"></Footer>
</template>

<script>
import axios from 'axios';
import Navbar from '@/components/navbar.vue';
import Footer from '@/components/footer.vue';
import NewPostsSidebar from '@/components/new-posts-sidebar.vue';

export default {
    name: 'SimilarPosts',
    components: {
        Navbar,
        Footer,
        NewPostsSidebar,
    },
    data() {
        return {
            items: null,
            sidebar_posts: null,
        }
    },
    mounted() {
        this.awake();
    },
    methods: {
        // awake and refresh data
        async awake() {
            document.title = 'History Of Colors - Similar Posts';
            this.scrollToTop();
            if(this.$route.params.slug && this.$route.params.pid)
            {
                this.scrollToTop();
                await axios.post('/api/v1/data/get/', {type: 'similar_posts', slug: this.$route.params.slug, pid: this.$route.params.pid}, this.$store.state.axios_config)
                    .then(response => {
                        this.items = response.data.posts;
                        this.$store.state.base_data = response.data.base;
                        this.sidebar_posts = response.data.new_posts;
                    })
                    .catch(error => {
                        if(this.$store.state.is_debug)
                            console.log(error);
                        if((error.response && error.response.status && error.response.status == 403) || (error.request && error.request.status && error.request.status == 403))
                            this.$store.dispatch('addToast', {msg: 'You do not have access to this information', type: 'error'});
                        else if((error.response && error.response.status && error.response.status == 404) || (error.request && error.request.status && error.request.status == 404))
                            this.$store.dispatch('addToast', {msg: 'No information found', type: 'error'});
                        else
                            this.$store.dispatch('addToast', {msg: 'An error has occurred on the server side, please contact support', type: 'error'});
                    });
            }
            if(!this.items || this.items.length == 0)
                this.$router.push({ path: '/404' });
            document.title = 'History Of Colors - ' + this.items[0].title;
            setTimeout(() => {
                this.check_post_images_aspect_ratio();
            }, 500);
        },
        // scroll page to top
        scrollToTop() {
            if(document.getElementById('wrapper'))
                document.getElementById('wrapper').scrollTo({ top: 0, behavior: 'smooth' });
        },
        check_post_images_aspect_ratio() {
            for(let i=0;i<this.items.length;i++)
            {
                let item_div = document.getElementById('post-' + String(i));
                let item_img = document.getElementById('post-image-' + String(i));
                let item_img_parent = document.getElementById('post-image-parent-' + String(i));
                if(item_img.offsetWidth > item_img.offsetHeight)
                {
                    item_img.classList.add('w-full');
                    item_img_parent.classList.add('w-full');
                    item_div.classList.add('flex-col', 'items-center', 'justify-start');
                }
                else
                {
                    item_img.classList.add('w-full', 'md:w-fit');
                    item_img_parent.classList.add('w-full', 'md:w-fit');
                    item_div.classList.add('flex-col', 'md:flex-row', 'items-center', 'justify-center');
                }
            }
        },
    },
}
</script>
