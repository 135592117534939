<template>
    <Navbar :use_bg="true" logo_size="small" logo_mobile_size="small" />
    <div class="w-full p-10 pt-8" style="font-family: Questrial;">
        <div class="flex flex-col items-center justify-start w-full bg-base-100 overflow-x-hidden overflow-y-auto" v-if="!is_cats && items && items.length > 0">
            <p class="text-red-500 font-bold text-4xl w-full text-center md:text-left whitespace-nowrap">{{ category_name }}</p>
            <div class="grid grid-cols-1 md:grid-cols-2 w-full mt-10 gap-2 md:gap-8">
                <template v-for="(item, index) in items" :key="index">
                  <div class="flex flex-col items-center justify-center w-full">
                    <div class="flex flex-col md:flex-row w-full items-center justify-center gap-8 md:max-h-[300px] overflow-hidden">
                        <div class="relative w-full md:max-w-[50%] max-h-60 h-full group overflow-hidden md:max-h-[300px]">
                            <router-link :to="'/post/' + item.slug">
                                <img class="object-cover w-full max-h-60 h-full md:max-h-[300px] transition-transform duration-500 ease-out group-hover:scale-110 cursor-pointer" :src="this.$store.state.base_url + item.personal_image" :alt="item.title + ' Image'" />
                            </router-link>
                      </div>
                      <div class="flex flex-col items-center justify-start gap-1 w-full">
                        <p class="text-sm font-bold w-full uppercase text-red-500 text-left">{{ item.content_category.name }}</p>
                        <router-link :to="'/artcategory/' + item.artist_category.slug" class="w-full" v-if="item.artist_category">
                            <p class="text-sm font-bold text-left uppercase w-full text-blue-400">{{ item.artist_category.name }}</p>
                        </router-link>
                        <p v-else class="text-sm font-bold text-left w-full uppercase text-blue-400">{{ item.custom_artist_category }}</p>
                        <p class="text-xl font-bold w-full text-black text-left">{{ item.title }}</p>
                        <p class="text-base text-gray-700 w-full font-semibold text-left">{{ item.short_description }}</p>
                      </div>
                    </div>
                    <div class="divider md:hidden"></div>
                  </div>
                </template>
            </div>
        </div>
        <template v-if="is_cats && items && items.length > 0">
            <p class="text-error font-bold text-4xl w-full text-center md:text-left whitespace-nowrap">Categories</p>
            <div class="grid grid-cols-1 md:grid-cols-2 w-full mt-10 gap-8 md:gap-16">
                <template v-for="(item, index) in items" :key="index">
                    <div class="flex flex-col items-center justify-center w-full gap-4" v-if="item.post">
                            <router-link target='_blank' :to="'/category/' + item.cat.slug" class="w-full">
                                <p class="text-red-500 font-bold text-2xl w-full text-left whitespace-nowrap">{{ item.cat.name }}</p>
                            </router-link>
                            <div class="flex flex-col md:flex-row w-full items-center justify-center gap-8 md:max-h-[300px] overflow-hidden">
                                <div class="relative w-full md:max-w-[250px] h-[300px] md:h-[250px] group overflow-hidden">
                                    <router-link :to="'/post/' + item.post.slug">
                                        <img class="object-cover w-full h-full transition-transform duration-500 ease-out group-hover:scale-110 cursor-pointer" :src="this.$store.state.base_url + item.post.personal_image" :alt="item.post.title + ' Image'" />
                                    </router-link>
                                </div>
                                <div class="flex flex-col items-center justify-start gap-1 w-full">
                                    <p class="text-xl font-bold w-full text-black text-left">{{ item.post.title }}</p>
                                    <router-link :to="'/category/' + item.post.content_category.slug" class="text-left w-full" v-if="item.post.content_category">
                                        <p class="text-sm font-bold uppercase text-red-500">{{ item.post.content_category.name }}</p>
                                    </router-link>
                                    <router-link :to="'/artcategory/' + item.post.artist_category.slug" class="w-full" v-if="item.post.artist_category">
                                        <p class="text-sm font-bold text-left uppercase w-full text-blue-400">{{ item.post.artist_category.name }}</p>
                                    </router-link>
                                    <p v-else class="text-sm font-bold text-left w-full uppercase text-blue-400">{{ item.post.custom_artist_category }}</p>
                                    <p class="text-base text-gray-700 w-full font-semibold text-left">{{ item.post.short_description }}</p>
                                </div>
                            </div>
                        <div class="divider md:hidden"></div>
                    </div>
                </template>
            </div>
        </template>
        <NoDataFound v-if="!items || items.length == 0" />
    </div>
    <Footer logo_size="normal" logo_mobile_size="small" class="p-10"></Footer>
</template>

<script>
import Navbar from '@/components/navbar.vue';
import Footer from '@/components/footer.vue';
import NoDataFound from '@/components/no-data-found.vue';
import axios from 'axios';

// icons
// import MagnifyIcon from 'vue-material-design-icons/Magnify.vue';

export default {
    name: 'ContentCategories',
    components: {
        Navbar,
        Footer,
        NoDataFound,
    },
    data() {
        return {
            items: [],
            category_name: '',
            categories: [],
            is_cats: false,
        }
    },
    mounted() {
        this.awake();
    },
    methods: {
        // awake and refresh data
        async awake() {
            document.title = 'History Of Colors - Categories';
            this.scrollToTop();
            this.$store.state.is_loading = true;
            await axios.post('/api/v1/data/get/', {type: 'content_category_data', slug: this.$route.params.slug ?? ''} , this.$store.state.axios_config)
                .then(response => {
                    this.items = response.data.posts;
                    this.category_name = response.data.category_name;
                    this.is_cats = response.data.is_cats;
                    this.$store.state.base_data = response.data.base;
                })
                .catch(error => {
                    if(error.response.status == 403)
                        this.$store.dispatch('addToast', {msg: 'You do not have access to this information', type: 'error'});
                    else if(error.response.status == 404)
                        this.$store.dispatch('addToast', {msg: 'No information found', type: 'error'});
                    else
                        this.$store.dispatch('addToast', {msg: 'An error has occurred on the server side, please contact support', type: 'error'});
                });
            setTimeout(() => {
                this.$store.state.is_loading = false;
            }, 500);
        },
        // scroll page to top
        scrollToTop() {
            if(document.getElementById('wrapper'))
                document.getElementById('wrapper').scrollTo({ top: 0, behavior: 'smooth' });
        }
    },
}
</script>
