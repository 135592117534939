<template>
    <div style="font-family: Questrial;"class="flex flex-col items-start justify-start w-full">
        <p class="text-black font-bold text-xl">Perspectives</p>
        <div class="flex flex-col sm:flex-row justify-between items-start w-full overflow-hidden sm:gap-8 mt-10">
            <!-- col 1 -->
            <div class="flex flex-col items-start justify-start w-full">
                <div class="flex flex-row items-center justify-start gap-6">
                    <div class="relative h-32 max-w-32 w-full group overflow-hidden">
                        <img class="object-cover w-full h-full transition-transform duration-500 ease-out group-hover:scale-110 cursor-pointer" src="@/assets/img/posts/2-4-200x300.jpg" alt="" />
                    </div>
                    <div class="flex flex-col items-center justify-start gap-2 w-full">
                        <p class="text-xs font-bold w-full uppercase text-red-500 text-left">LEADERSHIP DEVELOPMENT</p>
                        <p class="text-md font-bold w-full text-black text-left">Finding & Living with Purpose</p>
                    </div>
                </div>
                <div class="divider"></div>
                <div class="flex flex-row items-center justify-start gap-6">
                    <div class="relative h-32 max-w-32 w-full group overflow-hidden">
                        <img class="object-cover w-full h-full transition-transform duration-500 ease-out group-hover:scale-110 cursor-pointer" src="@/assets/img/posts/1705434246933-300x155.jpg" alt="" />
                    </div>
                    <div class="flex flex-col items-center justify-start gap-2 w-full">
                        <p class="text-xs font-bold w-full uppercase text-red-500 text-left">RESILIENCE</p>
                        <p class="text-md font-bold w-full text-black text-left">Bouncing Back from Job Loss</p>
                    </div>
                </div>
                <div class="divider"></div>
                <div class="flex flex-row items-center justify-start gap-6">
                    <div class="relative h-32 max-w-32 w-full group overflow-hidden">
                        <img class="object-cover w-full h-full transition-transform duration-500 ease-out group-hover:scale-110 cursor-pointer" src="@/assets/img/posts/2-5-300x200.jpg" alt="" />
                    </div>
                    <div class="flex flex-col items-center justify-start gap-2 w-full">
                        <p class="text-xs font-bold w-full uppercase text-red-500 text-left">CONFIDENCE</p>
                        <p class="text-md font-bold w-full text-black text-left">How did you overcome imposter syndrome?</p>
                    </div>
                </div>
            </div>
            <div class="divider sm:divider-horizontal"></div>
            <!-- col 2 -->
            <div class="flex flex-col items-start justify-start w-full">
                <div class="flex flex-row items-center justify-start gap-6">
                    <div class="relative h-32 max-w-32 w-full group overflow-hidden">
                        <img class="object-cover w-full h-full transition-transform duration-500 ease-out group-hover:scale-110 cursor-pointer" src="@/assets/img/posts/2-1-150x150.jpg" alt="" />
                    </div>
                    <div class="flex flex-col items-center justify-start gap-2 w-full">
                        <p class="text-xs font-bold w-full uppercase text-red-500 text-left">LEADERSHIP DEVELOPMENT</p>
                        <p class="text-md font-bold w-full text-black text-left">Developing the Power of Connection by Communicating Effectively</p>
                    </div>
                </div>
                <div class="divider"></div>
                <div class="flex flex-row items-center justify-start gap-6">
                    <div class="relative h-32 max-w-32 w-full group overflow-hidden">
                        <img class="object-cover w-full h-full transition-transform duration-500 ease-out group-hover:scale-110 cursor-pointer" src="@/assets/img/posts/2-2-682x1024.jpg" alt="" />
                    </div>
                    <div class="flex flex-col items-center justify-start gap-2 w-full">
                        <p class="text-xs font-bold w-full uppercase text-red-500 text-left">CONFIDENCE</p>
                        <p class="text-md font-bold w-full text-black text-left">Building Blocks of Success: Confidence & Self Esteem</p>
                    </div>
                </div>
                <div class="divider"></div>
                <div class="flex flex-row items-center justify-start gap-6">
                    <div class="relative h-32 max-w-32 w-full group overflow-hidden">
                        <img class="object-cover w-full h-full transition-transform duration-500 ease-out group-hover:scale-110 cursor-pointer" src="@/assets/img/posts/1-4-1-e1707199406150-768x579.jpeg" alt="" />
                    </div>
                    <div class="flex flex-col items-center justify-start gap-2 w-full">
                        <p class="text-xs font-bold w-full uppercase text-red-500 text-left">LEADERSHIP DEVELOPMENT</p>
                        <p class="text-md font-bold w-full text-black text-left">How did you find your purpose?</p>
                    </div>
                </div>
            </div>
            <div class="divider sm:divider-horizontal"></div>
            <!-- col 3 -->
            <div class="flex flex-col items-start justify-start w-full">
                <div class="flex flex-row items-center justify-start gap-6">
                    <div class="relative h-32 max-w-32 w-full group overflow-hidden">
                        <img class="object-cover w-full h-full transition-transform duration-500 ease-out group-hover:scale-110 cursor-pointer" src="@/assets/img/posts/2-7-768x512.jpg" alt="" />
                    </div>
                    <div class="flex flex-col items-center justify-start gap-2 w-full">
                        <p class="text-xs font-bold w-full uppercase text-red-500 text-left">PRODUCTIVITY</p>
                        <p class="text-md font-bold w-full text-black text-left">Perspectives on Staying Creative</p>
                    </div>
                </div>
                <div class="divider"></div>
                <div class="flex flex-row items-center justify-start gap-6">
                    <div class="relative h-32 max-w-32 w-full group overflow-hidden">
                        <img class="object-cover w-full h-full transition-transform duration-500 ease-out group-hover:scale-110 cursor-pointer" src="@/assets/img/posts/2-768x768.jpg" alt="" />
                    </div>
                    <div class="flex flex-col items-center justify-start gap-2 w-full">
                        <p class="text-xs font-bold w-full uppercase text-red-500 text-left">RESILIENCE</p>
                        <p class="text-md font-bold w-full text-black text-left">Betting on the Brightside: Developing and Fostering Optimism</p>
                    </div>
                </div>
                <div class="divider"></div>
                <div class="flex flex-row items-center justify-start gap-6 w-full">
                    <div class="relative h-32 max-w-32 w-full group overflow-hidden">
                        <img class="object-cover w-full h-full transition-transform duration-500 ease-out group-hover:scale-110 cursor-pointer" src="@/assets/img/posts/1-5-682x1024.jpeg" alt="" />
                    </div>
                    <div class="flex flex-col items-center justify-start gap-2 w-full">
                        <p class="text-xs font-bold w-full uppercase text-red-500 text-left">CONFIDENCE</p>
                        <p class="text-md font-bold w-full text-black text-left">Kicking Imposter Syndrome to the Curb</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'LandingPagePerspectives',
    }
</script>
