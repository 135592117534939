<template>

    <div class="fixed z-[99999] bottom-3 w-full justify-center flex flex-col items-center gap-3" v-if="this.$store.state.toast_items.length > 0" dir="rtl" style="font-family: Questrial;">
        <template v-for="(item, index) in this.$store.state.toast_items" :key="index">
            <template v-if="item">
                <ToastItem :toast-id="item.id" />
            </template>
        </template>
    </div>

</template>

<script>
import ToastItem from '@/components/toast-item.vue';
export default {
    name: 'ToastComponent',
    components: {
        ToastItem,
    },
}
</script>
