import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import axios from 'axios'
import './assets/css/app.css'
import './assets/css/style.css'

// axios.defaults.baseURL = 'http://127.0.0.1:8000'
axios.defaults.baseURL = 'https://historyofcolors.com'
// axios.defaults.baseURL = 'http://91.107.132.232'

createApp(App).use(store).use(router, axios).mount('#app')
